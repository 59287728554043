import { useRef } from 'react'

import { sessionPoll, sessionSync } from '../api/emulatorService'
import { POLL_STATUS } from '../constants'
import { ServerMessage } from '../swagger'
import { EmulatorSettings } from '../types'

interface SubscribeSyncProps {
  token: symbol
  message: ServerMessage
  lang?: string
}

interface UseLongPollingProps {
  emulatorSettings: EmulatorSettings
  processResponse: (data: ServerMessage) => Promise<void>
  defaultLang: string
}
export const useLongPolling = ({
  emulatorSettings,
  processResponse,
  defaultLang
}: UseLongPollingProps) => {
  const currentPollingToken = useRef<symbol | null>(null)
  const abortController = useRef<AbortController | null>(null)

  const subscribeSync = async ({ token, message, lang = defaultLang }: SubscribeSyncProps) => {
    try {
      if (message) {
        abortController.current = new AbortController()

        const pollRequest = {
          sceneId: emulatorSettings.sceneId,
          deviceClass: emulatorSettings.deviceClass,
          sessionId: message.sessionId,
          messageId: message.messageId,
          clientLanguage: lang
        }

        const { data: pollResult } = await sessionPoll({
          payload: pollRequest,
          apiUrl: emulatorSettings.useSecondaryUrl
            ? emulatorSettings.apiUrlCS
            : emulatorSettings.apiUrl,
          axiosConfig: { signal: abortController.current.signal }
        })

        if (pollResult.pollStatus === POLL_STATUS.ePollStatusSync) {
          stopSubscribeSync()

          const { data } = await sessionSync({
            payload: pollRequest,
            apiUrl: emulatorSettings.useSecondaryUrl
              ? emulatorSettings.apiUrlCS
              : emulatorSettings.apiUrl
          })
          await processResponse(data)

          startSubscribeSync(data)
        }
      }
    } catch (error: unknown) {
      // Check if the error is an Axios cancel
      if (error instanceof Error) {
        // Type is narrowed to Error, safe to access Error properties
        console.error('🚀 ~ subscribeSync ~ error:', error.message)
      } else {
        // Generic error logging as we don't know the exact type
        console.error('🚀 ~ subscribeSync ~ error:', error)
      }
    } finally {
      await new Promise(resolve => setTimeout(resolve, 1000)) // Sleep for 1 second

      // Only re-call if the token is still the current one
      if (token === currentPollingToken.current) {
        await subscribeSync({ token, message, lang })
      }
    }
  }

  const stopSubscribeSync = () => {
    if (abortController.current) {
      abortController.current.abort()
    }
    currentPollingToken.current = null
  }

  const startSubscribeSync = (data: ServerMessage) => {
    const newToken = Symbol() // Generate a unique new token
    currentPollingToken.current = newToken
    subscribeSync({ token: newToken, message: data })
  }

  return {
    stopSubscribeSync,
    startSubscribeSync
  }
}
