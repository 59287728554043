import axios, { AxiosInstance } from 'axios'

import { handleError } from '../helpers/handleApiError'

axios.defaults.timeout = 1000 * 60 * 60 * 3

export const PY_DEV_API = window?.GLOBAL_ENV?.REACT_APP_PY_URL
export const NG_DEV_API = window?.GLOBAL_ENV?.REACT_APP_NG_URL

export enum ServiceApi {
  PY_DEV = 'PY_DEV',
  NG_DEV = 'NG_DEV',
  CUSTOM = 'CUSTOM'
}

export const SERVICE_API_MAP = {
  [ServiceApi.PY_DEV]: PY_DEV_API,
  [ServiceApi.NG_DEV]: NG_DEV_API,
  [ServiceApi.CUSTOM]: ''
}

export const initClient = (baseURL: string): AxiosInstance => {
  const axiosInstance = axios.create({ baseURL: baseURL || PY_DEV_API })

  axiosInstance.interceptors.response.use(undefined, handleError)

  return axiosInstance
}
