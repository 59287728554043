export const API_VERSION = '0.6.0'
import { components } from './schema'

export type SessionStartData = components['schemas']['sessionStartData']
export type ServerMessage = components['schemas']['serverMessage']
export type eDeviceClass = components['schemas']['eDeviceClass']
export type eDeviceErrorCode = components['schemas']['eDeviceErrorCode']
export type eMessageType = components['schemas']['eMessageType']
export type Lang = components['schemas']['lang']
export type DeviceMessage = components['schemas']['deviceMessage']
export type DeviceHardwareStatus = components['schemas']['deviceHardwareStatus']

export type AuxStaticScreenData = components['schemas']['auxStaticScreenData']

export type UserEntryScreenData = components['schemas']['userEntryScreenData'] & {
  statusScreens?: Record<string, AuxStaticScreenData>
}
export type UserEntryInputElement = components['schemas']['userEntryInputElement']
export type UserEntryCommandElement = components['schemas']['userEntryCommandElement'] & {
  type?: string
}
export type UserEntryStaticElement = components['schemas']['userEntryStaticElement']
export type UserEntryInputElementType = components['schemas']['userEntryInputElementType']

export type AcceptData = components['schemas']['acceptData']
export type DispenseData = components['schemas']['dispenseData']
export type ReceiptData = components['schemas']['receiptData']
export type LabelData = components['schemas']['labelData']
export type TableData = components['schemas']['tableData']
export type QrData = components['schemas']['qrData']
export type InputData = components['schemas']['inputData']
export type InputHandler = components['schemas']['eInputHandler']
export type HtmlData = components['schemas']['htmlData']
export type CheckboxData = components['schemas']['checkboxData']

export type AcceptApproveData = components['schemas']['acceptApproveData']
export type AcceptResponse = components['schemas']['acceptResponse']
export type NotesCollection = components['schemas']['notesCollection']

export type ListEntry = components['schemas']['listEntry']
export type ListData = components['schemas']['listData']

export type FaceRecognitionStartData = components['schemas']['faceRecognitionStartData']
export type GetDescriptorsFromImageRequest = { onlyBestShot: boolean }
