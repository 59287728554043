import { ChangeEvent, FC, useContext, useState } from 'react'

import { Context } from '../../App'

interface Props {
  isLoading: boolean
  isHardwareWebService: boolean
}

export const LoadMoneyControl: FC<Props> = ({ isLoading, isHardwareWebService }) => {
  const [inputValue, setInputValue] = useState('')

  const {
    acceptCash: { handleLoad, isUserChoosing }
  } = useContext(Context)

  const handleClick = () => {
    handleLoad(inputValue)
    setInputValue('')
  }

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    const regex = /^\d*(,\d+)*,?\d*$/

    if (regex.test(e.target.value)) {
      setInputValue(e.target.value)
    }
  }

  if (isHardwareWebService && isLoading) {
    return <div>Loading...</div>
  }

  return !isHardwareWebService ? (
    <div style={{ display: 'flex', gap: '10px' }}>
      <input
        placeholder="Enter value"
        disabled={isUserChoosing}
        value={inputValue}
        onChange={handleInput}
      />
      <button type="button" disabled={!inputValue.length || isUserChoosing} onClick={handleClick}>
        load
      </button>
    </div>
  ) : null
}
