import { useContext, useEffect, useState } from 'react'

import { Context } from '../App'
import { concatenateObjects } from '../helpers/concatenateObjects'
import { changePayload } from '../helpers/utils'
import { AcceptData, NotesCollection } from '../swagger'
import { CommandPayload } from '../types'

export interface AcceptCashResponse {
  isUserChoosing: boolean
  denominations: string[]
  collectedTotal: NotesCollection
  setIsUserChoosing: React.Dispatch<React.SetStateAction<boolean>>
  setDenominations: React.Dispatch<React.SetStateAction<Array<string>>>
  handleLoad: (inputValue: string) => void
}

interface AcceptCashProps {
  messageData: AcceptData | null
  commandPayload: CommandPayload[]
  setCommandPayload: React.Dispatch<React.SetStateAction<CommandPayload[]>>
}

interface AcceptCommand {
  collectedCycle: NotesCollection
  collectedTotal: NotesCollection
}

const ACCEPT_ID = 'acceptId'

export const useAcceptCash = ({
  messageData,
  commandPayload,
  setCommandPayload
}: AcceptCashProps): AcceptCashResponse => {
  const [isUserChoosing, setIsUserChoosing] = useState(false)
  const [denominations, setDenominations] = useState<string[]>([])

  const accept = commandPayload.find(item => item.objectId === ACCEPT_ID)

  const { collectedTotal: commandCollectedTotal = [] } = (accept?.responseData ||
    {}) as AcceptCommand

  const { acceptableNotes, collectedSoFar } = messageData || {}

  useEffect(() => {
    return () => {
      setDenominations([])
      setIsUserChoosing(false)
    }
  }, [])

  const savedDenominations = (collectedSoFar || []).reduce(
    (acc, coll) => {
      return {
        ...acc,
        [coll.note?.denomination || '']: coll.count || 0
      }
    },
    {} as Record<string, number>
  )

  const handleLoad = (inputValue: string) => {
    if (inputValue.length) {
      const _denominations = inputValue.replaceAll(' ', '').split(',')

      const inputDenominations = _denominations.reduce(
        (acc, denom) => ({ ...acc, [denom]: (acc[denom] || 0) + 1 }),
        {} as Record<string, number>
      )

      const denominationsCount = concatenateObjects(savedDenominations, inputDenominations)

      const _collectedTotal: NotesCollection = Object.keys(denominationsCount).map(curr => ({
        count: denominationsCount[curr],
        note: { currency: acceptableNotes?.[0].currency, denomination: Number(curr) }
      }))

      const _collectedCycle = _collectedTotal.filter(curr => {
        const foundNote = commandCollectedTotal.find(
          item => item.note?.denomination === curr.note?.denomination
        )
        if (!foundNote) return true
        return (foundNote?.count || 0) < (curr?.count || 0)
      })

      setCommandPayload(
        changePayload({
          objectId: ACCEPT_ID,
          responseData: {
            collectedCycle: _collectedCycle,
            collectedTotal: _collectedTotal || []
          }
        })
      )

      setIsUserChoosing(true)
    }
  }

  return {
    isUserChoosing,
    denominations,
    collectedTotal: commandCollectedTotal,
    handleLoad,
    setDenominations,
    setIsUserChoosing
  }
}

export interface AcceptProps {
  messageData: AcceptData
}

export const useAccept = (props: AcceptProps) => {
  const { messageData } = props
  const { limits, acceptableNotes, collectedSoFar } = messageData

  const {
    acceptCash: { collectedTotal, isUserChoosing, setIsUserChoosing }
  } = useContext(Context)

  useEffect(() => {
    return () => {
      setIsUserChoosing(false)
    }
  }, [collectedSoFar])

  return {
    limits,
    acceptableNotes,
    collectedTotal,
    isUserChoosing,
    setIsUserChoosing
  }
}
