import { TableData } from '../../../../swagger'

import styles from './styles.module.css'

interface Props {
  elementData: TableData
}

export const Table = ({ elementData }: Props) => {
  return (
    <div className={styles.tableWrapper}>
      <table className={styles.table}>
        <tbody>
          {elementData?.rows?.map((row, index) => (
            <tr key={index}>
              {row.elements?.map((el, rowIndex) => (
                <td key={rowIndex} className={styles.cell}>
                  {el}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
