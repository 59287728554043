import { FC, useContext } from 'react'

import { Context } from '../../App'

export const Timeout: FC = () => {
  const { timeout } = useContext(Context)

  if (timeout.countdown && timeout.countdown > -1 && timeout.showTimer) {
    return <div>Session finished. New session in {timeout.countdown} seconds</div>
  }

  return null
}
