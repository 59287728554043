import { useState } from 'react'

import { SERVICE_API_MAP, ServiceApi } from '../api/client'
import { DEVICE_CLASSES } from '../constants'
import { LocalStorageKeys } from '../constants/localStorage'
import { EmulatorSetting, EmulatorSettings } from '../types'

const defaultEmulatorSettings = {
  [EmulatorSetting.atmUIIndex]: 0,
  [EmulatorSetting.sceneId]: null,
  [EmulatorSetting.deviceClass]: DEVICE_CLASSES.eDeviceClassKiosk,
  [EmulatorSetting.apiUrl]: SERVICE_API_MAP[ServiceApi.PY_DEV],
  [EmulatorSetting.apiVariant]: ServiceApi.PY_DEV,
  [EmulatorSetting.useSecondaryUrl]: false,
  [EmulatorSetting.isHardwareWebService]: false,
  [EmulatorSetting.apiUrlCS]: '',
  [EmulatorSetting.terminalId]: 'FCX-EMUL-DEV-API'
}

export const useEmulatorSettings = () => {
  const settingsFromLS = JSON.parse(localStorage.getItem(LocalStorageKeys.EmulatorSettings) || '{}')

  const [emulatorSettings, setEmulatorSettings] = useState<EmulatorSettings>({
    ...defaultEmulatorSettings,
    ...settingsFromLS
  })

  const handleEmulatorSettings = (entry: Partial<EmulatorSettings>) => {
    setEmulatorSettings(prev => ({ ...prev, ...entry }))

    if (!(EmulatorSetting.sceneId in entry)) {
      localStorage.setItem(
        LocalStorageKeys.EmulatorSettings,
        JSON.stringify({ ...settingsFromLS, ...entry })
      )
    }
  }

  return {
    emulatorSettings,
    handleEmulatorSettings
  }
}
