import { eDeviceClass, eMessageType } from '../swagger'
import { LangList } from '../types'

export const ELEMENT_TYPES = {
  eList: 'eList',
  eInput: 'eInput',
  eCheckbox: 'eCheckbox',
  eNumpad: 'eNumpad',
  eButton: 'eButton',
  eQR: 'eQR',

  eLabel: 'eLabel',
  eTable: 'eTable',
  eHtml: 'eHtml'
} as const

export type ElementTypeInput = (typeof ELEMENT_TYPES)[keyof typeof ELEMENT_TYPES]

export const DEVICE_CLASSES: Record<string, eDeviceClass> = {
  eDeviceClassATM: 'eDeviceClassATM',
  eDeviceClassKiosk: 'eDeviceClassKiosk',
  eDeviceClassMobile: 'eDeviceClassMobile'
}

export const DEVICE_CLASSES_LIST = [
  DEVICE_CLASSES.eDeviceClassATM,
  DEVICE_CLASSES.eDeviceClassKiosk,
  DEVICE_CLASSES.eDeviceClassMobile
]

enum Scenario {
  adm = 'adm',
  fcx = 'fcx',
  fcx2 = 'fcx2',
  edit = 'edit',
  qr = 'qr',
  kapital = 'kapital',
  payments = 'payments',
  sarunasTest = 'sarunas_test',
  uiDebug = 'ui_debug',
  eurasianBank = 'eurasian_bank',
  scenarioSelection = 'scenario_selection',
  cardless = 'cardless',
  azerTurk = 'azerTurk',
  eurasianCredit = 'eurasianCredit',
  lab = 'lab',
  "admNew" = 'admNew'
}

export enum ScenarioGroup {
  Products = 'products',
  Development = 'development',
  Customer = 'customer'
}

type ScenarioItem = { name: Scenario; useSecondaryUrl?: boolean }

export const scenarios: Record<ScenarioGroup, ScenarioItem[]> = {
  [ScenarioGroup.Products]: [
    { name: Scenario.adm },
    { name: Scenario.fcx },
    { name: Scenario.qr },
    { name: Scenario.payments },
    { name: Scenario.cardless }
  ],
  [ScenarioGroup.Development]: [
    { name: Scenario.sarunasTest },
    { name: Scenario.uiDebug },
    { name: Scenario.scenarioSelection },
    { name: Scenario.fcx, useSecondaryUrl: true },
    { name: Scenario.fcx2, useSecondaryUrl: true },
    { name: Scenario.kapital, useSecondaryUrl: true },
    { name: Scenario.edit, useSecondaryUrl: true },
    { name: Scenario.azerTurk, useSecondaryUrl: true },
    { name: Scenario.lab, useSecondaryUrl: true },
    {name: Scenario.eurasianCredit, useSecondaryUrl: true},
    {name: Scenario.admNew, useSecondaryUrl: true}
  ],
  [ScenarioGroup.Customer]: [{ name: Scenario.kapital }, { name: Scenario.eurasianBank }]
}

export const LANGUAGES_LIST: LangList[] = [
  {
    key: 'en',
    name: 'English',
    short: 'gb',
    position: 'r1'
  },
  {
    key: 'ru',
    name: 'Russian',
    short: 'ru',
    position: 'l1'
  }
]

export const MESSAGE_TYPE: Record<string, eMessageType> = {
  eSessionComplete: 'eSessionComplete',
  eUserEntryScreen: 'eUserEntryScreen',
  eAccept: 'eAccept',
  eAcceptApprove: 'eAcceptApprove',
  eDispense: 'eDispense',
  eReceipt: 'eReceipt',
  eFaceRecognition: 'eFaceRecognition'
}

export const POLL_STATUS = {
  ePollStatusNone: 'ePollStatusNone',
  ePollStatusSync: 'ePollStatusSync'
}
