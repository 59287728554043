import React, { FC, useState } from 'react'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import {
  Alert,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  TextField,
  Typography
} from '@mui/material'

import { SERVICE_API_MAP, ServiceApi } from '../../api/client'
import { SCREEN_LIST } from '../../App'
import { DEVICE_CLASSES, DEVICE_CLASSES_LIST } from '../../constants'
import { eDeviceClass } from '../../swagger'
import { EmulatorSetting } from '../../types'

import { Props } from './types'

import './styles.css'

export const Settings: FC<Props> = props => {
  const { emulatorSettings, handleEmulatorSettings } = props
  const [anchorEl, setAnchorEl] = useState<SVGSVGElement | null>(null)

  const open = Boolean(anchorEl)
  const id = open ? 'settings-popover' : undefined

  const handleClick = (event: React.MouseEvent<SVGSVGElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <SettingsOutlinedIcon className="settings-icon" aria-describedby={id} onClick={handleClick} />

      {emulatorSettings.deviceClass === DEVICE_CLASSES.eDeviceClassMobile && (
        <Alert sx={{ m: '10px' }} variant="outlined" severity="warning">
          Mobile variant only for Backend testing
        </Alert>
      )}
      <Popover
        id={id}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        classes={{ paper: 'settings-popover' }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Typography className="settings-title" variant="h6">
          Settings
        </Typography>
        <div className="settings-form">
          <FormControl variant="outlined">
            <InputLabel shrink>ATM Screen type</InputLabel>
            <Select
              fullWidth
              variant="outlined"
              label="ATM Screen type"
              value={emulatorSettings[EmulatorSetting.atmUIIndex]}
              className="field"
              onChange={e => {
                handleEmulatorSettings({
                  [EmulatorSetting.atmUIIndex]: Number(e.target.value)
                })
                window.location.reload()
              }}
            >
              {SCREEN_LIST.map((item, i) => (
                <MenuItem key={i} value={i}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined">
            <InputLabel shrink>DeviceClass</InputLabel>
            <Select
              label="deviceClass"
              className="field"
              value={emulatorSettings[EmulatorSetting.deviceClass]}
              onChange={e => {
                handleEmulatorSettings({
                  [EmulatorSetting.deviceClass]: e.target.value as eDeviceClass
                })
              }}
            >
              {DEVICE_CLASSES_LIST.map(item => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div className="apiSelecting">
            <div>
              <FormControl variant="outlined">
                <InputLabel shrink>Select Api</InputLabel>
                <Select
                  label="deviceClass"
                  className="field"
                  value={emulatorSettings[EmulatorSetting.apiVariant]}
                  onChange={e => {
                    handleEmulatorSettings({
                      [EmulatorSetting.apiVariant]: e.target.value,
                      [EmulatorSetting.apiUrl]:
                        SERVICE_API_MAP[e.target.value as keyof typeof SERVICE_API_MAP]
                    })
                  }}
                >
                  {Object.keys(SERVICE_API_MAP).map(key => (
                    <MenuItem key={key} value={key}>
                      {key}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {emulatorSettings[EmulatorSetting.apiVariant] !== ServiceApi.CUSTOM && (
                <div className="activeUrl">Url: {emulatorSettings[EmulatorSetting.apiUrl]}</div>
              )}
            </div>
            {emulatorSettings[EmulatorSetting.apiVariant] === ServiceApi.CUSTOM && (
              <TextField
                sx={{ mb: '8px' }}
                fullWidth
                className="text-field"
                variant="outlined"
                InputLabelProps={{
                  shrink: true
                }}
                label="api_url"
                placeholder="api_url"
                value={emulatorSettings[EmulatorSetting.apiUrl]}
                onChange={e => {
                  handleEmulatorSettings({
                    [EmulatorSetting.apiUrl]: e.target.value
                  })
                }}
              />
            )}
          </div>

          <TextField
            fullWidth
            className="text-field"
            variant="outlined"
            label="api_url_c#"
            placeholder="api_url_c#"
            InputLabelProps={{
              shrink: true
            }}
            value={emulatorSettings[EmulatorSetting.apiUrlCS]}
            onChange={e => {
              handleEmulatorSettings({
                [EmulatorSetting.apiUrlCS]: e.target.value
              })
            }}
          />
          <TextField
            fullWidth
            className="text-field"
            variant="outlined"
            label="device Name"
            placeholder="device Name"
            InputLabelProps={{
              shrink: true
            }}
            value={emulatorSettings[EmulatorSetting.terminalId]}
            onChange={e => {
              handleEmulatorSettings({
                [EmulatorSetting.terminalId]: e.target.value
              })
            }}
          />
          <FormControlLabel
            checked={emulatorSettings[EmulatorSetting.isHardwareWebService]}
            onChange={(event, checked) => {
              handleEmulatorSettings({
                [EmulatorSetting.isHardwareWebService]: checked
              })
            }}
            control={<Checkbox />}
            label="Use Hardware WebService"
          />
        </div>
      </Popover>
    </>
  )
}
