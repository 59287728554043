import React from 'react'

import { commonIconsMap } from '../../../../assets/icons/icons.map'

import { ReactComponent as BanknoteBig } from './banknote_big.svg'
import { ReactComponent as ExchangeError } from './exchange_error.svg'
import { ReactComponent as ExclamationMarkRed } from './ic_exclamation_mark_red.svg'
import { ReactComponent as PurseOutline } from './purse_outline.svg'
import { ReactComponent as SandClock } from './sand_clock.svg'
import { ReactComponent as ThanksForUsingUs } from './thanks_for_using_us.svg'
import { ReactComponent as TickBig } from './tick_big.svg'

type IconMap = Record<
  string,
  React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string
    }
  >
>

export const iconsMap: IconMap = {
  ...commonIconsMap,
  'purse_outline': PurseOutline,
  'banknote_big': BanknoteBig,
  'tick_big': TickBig,
  'thanks_for_using_us': ThanksForUsingUs,
  'sand_clock': SandClock,
  'ic_exclamation_mark_red': ExclamationMarkRed,
  'exchange_error': ExchangeError,
}
