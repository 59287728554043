import React from 'react'

import {
  eDeviceClass,
  ServerMessage,
  UserEntryCommandElement,
  UserEntryInputElement,
  UserEntryInputElementType,
  UserEntryStaticElement
} from '../swagger'

export type HardwareButtonPosition = 'l1' | 'l2' | 'l3' | 'l4' | 'r1' | 'r2' | 'r3' | 'r4'

export enum EmulatorSetting {
  atmUIIndex = 'atmUIIndex',
  sceneId = 'sceneId',
  deviceClass = 'deviceClass',
  apiVariant = 'apiVariant',
  apiUrl = 'apiUrl',
  apiUrlCS = 'apiUrlCS',
  useSecondaryUrl = 'useSecondaryUrl',
  isHardwareWebService = 'isHardwareWebService',
  terminalId = 'terminalId'
}

export type LangList = {
  key: string
  name: string
  short: string
  position: HardwareButtonPosition
}

export type EmulatorSettings = {
  [EmulatorSetting.atmUIIndex]: number
  [EmulatorSetting.sceneId]: string | null
  [EmulatorSetting.deviceClass]: eDeviceClass
  [EmulatorSetting.apiVariant]: string
  [EmulatorSetting.apiUrl]: string
  [EmulatorSetting.apiUrlCS]: string
  [EmulatorSetting.useSecondaryUrl]: boolean
  [EmulatorSetting.isHardwareWebService]: boolean
  [EmulatorSetting.terminalId]: string
}

export type ChangePayloadProps = {
  objectId?: string
  isOptional?: boolean
  responseData?: unknown
}

export type SendMessageProps = {
  objectId?: string
  responseData?: unknown
}

export type CommandPayload = {
  inputElementType?: UserEntryInputElementType
  objectId?: string
  isOptional?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  responseData?: any
}

export type inPutValidationProps = {
  isValid: boolean
  isAcceptLoading: boolean
}

export type TimeoutCountdown = {
  countdown: number | null
  showTimer: boolean
}

export interface BaseProps {
  commandPayload: CommandPayload[]

  setCommandPayload: React.Dispatch<React.SetStateAction<CommandPayload[]>>
  sendMessage: ({ objectId, responseData }: SendMessageProps) => Promise<void>
}
export interface AtmUIProps extends BaseProps {
  lang: string
  joinSecret: string
  serverMessage: ServerMessage | null
  setLang: (lang: string) => Promise<void>
  deviceClass: string
  validation: inPutValidationProps
  messageLoading: boolean
}

export enum CommandType {
  cancelCommand = 'cancelCommand',
  correctCommand = 'correctCommand',
  confirmCommand = 'confirmCommand'
}

export type Element = UserEntryCommandElement & UserEntryInputElement & UserEntryStaticElement
