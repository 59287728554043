import { FC, ReactNode } from 'react'
import { Button as MuiButton } from '@mui/material'

import { hardwareButtonMatcher } from '../../../../helpers/hardwareButtonMatcher'
import { HardwareButtonPosition } from '../../../../types'

import styles from './styles.module.css'

export interface Props {
  value: ReactNode
  position: HardwareButtonPosition
  clearEvent?: boolean
  disabled?: boolean
  click: (() => void) | null
}

export const Button: FC<Props> = props => {
  const { value, position, clearEvent = true, disabled = false, click } = props

  const style = hardwareButtonMatcher({
    position,
    clearEvent: !disabled ? clearEvent : false,
    event: !disabled ? click : null
  })

  return (
    <MuiButton
      disabled={disabled}
      disableRipple
      style={style}
      variant="outlined"
      className={styles.button}
    >
      {value}
    </MuiButton>
  )
}
