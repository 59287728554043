import classNames from 'classnames'

import { ScenarioGroup, scenarios } from '../../constants'
import { EmulatorSetting, EmulatorSettings } from '../../types'

import styles from './styles.module.css'

interface Props {
  handleEmulatorSettings: (entry: Partial<EmulatorSettings>) => void
}

export const Scenarios = ({ handleEmulatorSettings }: Props) => {
  return (
    <div className={styles.wrapper}>
      {Object.keys(scenarios).map(key => (
        <div key={key} className={styles.group}>
          <h5 className={styles.title}>{key}</h5>
          <div className={styles.cards}>
            {scenarios[key as ScenarioGroup].map(device => (
              <button
                key={device.name}
                className={classNames(styles.card, {
                  [styles.secondaryUrl]: device.useSecondaryUrl
                })}
                onClick={async () => {
                  handleEmulatorSettings({
                    [EmulatorSetting.sceneId]: device.name,
                    [EmulatorSetting.useSecondaryUrl]: device.useSecondaryUrl
                  })
                }}
              >
                {device.name}
              </button>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}
