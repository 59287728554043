import { FC } from 'react'
import classNames from 'classnames'

import { Props } from './types'

import styles from './styles.module.css'

export const PageTitle: FC<Props> = ({ className, title }) => {
  const pageTitle = title?.text
  const pageSubTitle = title?.subText

  return (
    <div className={classNames(styles.pageTitle, className)}>
      {pageTitle && <p className={styles.title}>{pageTitle}</p>}
      {pageSubTitle && <span className={styles.pageSubTitle}>{pageSubTitle}</span>}
    </div>
  )
}
