import { FC, useState } from 'react'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { MenuItem, Select, SelectChangeEvent } from '@mui/material'

import { LANGUAGES_LIST } from '../../constants'
import FlagIcon from '../FlagIcon'

import { Props } from './types'

import './styles.css'

export const SetLocale: FC<Props> = props => {
  const { languages = LANGUAGES_LIST, selectedLang, setLanguage } = props
  const [open, setOpen] = useState(false)

  const onLanguageChange = (event: SelectChangeEvent<string>): void => {
    setLanguage(event.target.value)
  }

  const handleOpen = (): void => {
    setOpen(true)
  }

  const handleClose = (): void => {
    setOpen(false)
  }

  return (
    <Select
      className="setLanguage-select"
      classes={{ select: 'setLanguage-menu' }}
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      value={selectedLang}
      IconComponent={ExpandMore}
      onChange={onLanguageChange}
    >
      {languages.map(({ key, name, short }) => (
        <MenuItem key={key} value={key}>
          <div className="setLanguage-iconWrapper">
            <FlagIcon code={short} size="lg" className="setLanguage-size" />
          </div>
          <span className="setLanguage-label">{name}</span>
        </MenuItem>
      ))}
    </Select>
  )
}
