import React, { FC, useState } from 'react'
import QRCode from 'react-qr-code'
import QrCodeOutlinedIcon from '@mui/icons-material/QrCodeOutlined'
import { Box, Popover } from '@mui/material'

import { Props } from './types'

import styles from './styles.module.css'

export const QrCodeSync: FC<Props> = props => {
  const { joinSecret } = props

  const [anchorEl, setAnchorEl] = useState<SVGSVGElement | null>(null)

  const open = Boolean(anchorEl)
  const id = open ? 'qrCode-popover' : undefined

  const handleClick = (event: React.MouseEvent<SVGSVGElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return joinSecret ? (
    <>
      <QrCodeOutlinedIcon className={styles.qrCode} onClick={handleClick} />
      <Popover
        id={id}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        classes={{ paper: styles.qrCodePopover }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Box>
          <QRCode size={128} value={joinSecret} />
        </Box>
      </Popover>
    </>
  ) : null
}
