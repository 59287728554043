import { HardwareButtonPosition } from '../types'

type HardwareButtonMatcher = {
  position: HardwareButtonPosition
  clearEvent: boolean
  event: ((ev: MouseEvent) => void) | null
}

export const hardwareButtonMatcher = ({ position, clearEvent, event }: HardwareButtonMatcher) => {
  const hardwareButton = document.getElementById(position)

  const handleOnClick = (ev: MouseEvent): void => {
    if (event) event(ev)

    if (clearEvent) {
      const hardwareButtons: NodeListOf<HTMLButtonElement> =
        document.querySelectorAll('.hardware-button')
      hardwareButtons.forEach(button => (button.onclick = null))
    }
  }

  if (hardwareButton) {
    hardwareButton.onclick = handleOnClick
  }

  const style: React.CSSProperties = {
    display: hardwareButton ? 'flex' : 'none',
    position: 'absolute',
    top: hardwareButton?.offsetTop,
    [position[0] === 'l' ? 'left' : 'right']: '10px',
    justifyContent: position[0] === 'l' ? 'flex-start' : 'flex-end'
  }

  return style
}
