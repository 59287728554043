import { useRef, useState } from 'react'

const WSS_SOCKET_API_URL = 'wss://172.28.195.7:5000/bestShot'

type SocketResponse = {
  type: number
  target: string
  arguments: string
}

type IncomingArguments = {
  // eslint-disable-next-line camelcase
  img_b64: string
}

const handleSocketOpen = (socket: WebSocket): void => {
  console.log('🚀 ~ socket.opened')

  if (socket) {
    socket.send(`{"protocol": "json", "version": 1}`)
  }
}

const handleSocketClose = (
  socket: React.MutableRefObject<WebSocket | null>,
  isConnected: React.MutableRefObject<boolean>,
  reconnectOnClose: React.MutableRefObject<boolean>,
  retryTime: React.MutableRefObject<number>,
  connectWebSocket: () => void
): void => {
  socket.current = null
  isConnected.current = false

  if (!reconnectOnClose.current) {
    console.log('🚀 ~ socket.closed by client')
    return
  }
  console.log('🚀 ~ socket.closed by server')

  setTimeout(() => {
    if (retryTime.current) {
      connectWebSocket()
      retryTime.current = retryTime.current - 1
    }
  }, 3000)
}

const handleSocketError = (): void => {
  console.log('🚀 ~ socket.error')
}

export const useSocket = () => {
  const socket = useRef<WebSocket | null>(null)
  const isConnected = useRef<boolean>(false)
  const reconnectOnClose = useRef<boolean>(true)
  const retryTime = useRef(3)

  const [isLoading, setIsLoading] = useState(false)
  const [image, setImage] = useState('')

  const connectWebSocket = (): void => {
    try {
      if (!socket.current && !isConnected.current) {
        setIsLoading(true)
        socket.current = new WebSocket(WSS_SOCKET_API_URL)

        socket.current.onopen = (): void => {
          if (socket.current) {
            isConnected.current = true
            handleSocketOpen(socket.current)
          }
        }
        //
        socket.current.onmessage = (event: MessageEvent): void => {
          if (event.data) {
            const parsedData: SocketResponse = JSON.parse(event.data.slice(0, -1))
            if (parsedData.arguments) {
              setIsLoading(false)
              const incomingArguments: IncomingArguments = JSON.parse(parsedData.arguments)

              setImage(incomingArguments.img_b64)
            }
          }
        }

        const { close } = socket.current
        socket.current.close = (): void => {
          reconnectOnClose.current = false
          close.call(socket.current)
        }
        socket.current.onclose = (): void => {
          handleSocketClose(socket, isConnected, reconnectOnClose, retryTime, connectWebSocket)
        }
        socket.current.onerror = handleSocketError
      }
    } catch (error) {
      console.log('🚀 ~ WS ~ error', error)
    }
  }

  const close = () => {
    if (socket.current) {
      socket.current.close()
    }
  }

  return { image, isLoading, connectWebSocket, closeConnection: close }
}
