export const concatenateObjects = (
  objA: Record<string, number>,
  objB: Record<string, number>
): Record<string, number> => {
  const result: Record<string, number> = { ...objA }

  for (const key in objB) {
    if (Object.hasOwn(result, key)) {
      result[key] += objB[key]
    } else {
      result[key] = objB[key]
    }
  }

  return result
}
