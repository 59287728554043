import { FC } from 'react'

interface Props {
  html?: string
  className?: string
}

export const HTMLContent: FC<Props> = ({ html, className }) => {
  return html ? <div className={className} dangerouslySetInnerHTML={{ __html: html }} /> : null
}
