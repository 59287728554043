import { Button } from '@mui/material'
import classNames from 'classnames'
import clsx from 'clsx'

import { ELEMENT_TYPES } from '../../../../constants'
import { useAccept } from '../../../../hooks/useAcceptCash'
import { AcceptData, LabelData, TableData, UserEntryCommandElement } from '../../../../swagger'
import { BaseTemplateProps } from '../../../../types/templates'

import styles from './styles.module.css'

enum Labels {
  Attention = 'bs2p_label:attention',
  LoanRepayment = 'bs2p_label:loan_repayment',
  PleaseInsert = 'bs2p_label:please_insert'
}

export const Accept = (props: BaseTemplateProps<AcceptData>) => {
  const { messageData, sendMessage, ...rest } = props

  const { title, staticElements, currency, cancelCommand, correctCommand, confirmCommand } =
    messageData
  const pageTitle = title?.text

  const { collectedTotal, isUserChoosing } = useAccept({
    messageData,
    ...rest
  })

  const labels = (staticElements ?? [])

    .filter(elem => elem.staticElementType === ELEMENT_TYPES.eLabel)
    ?.reduce(
      (acc, elem) => {
        if (!elem.objectId || !elem.elementData) {
          return acc
        }
        return { ...acc, [elem.objectId]: elem.elementData as LabelData }
      },
      {} as Record<string, LabelData>
    )

  const table = (staticElements ?? []).find(elem => elem.staticElementType === ELEMENT_TYPES.eTable)

  const renderLabel = (elementData: LabelData, className?: string) => {
    const { text: labelText, subText } = elementData

    return (
      <div className={clsx(styles.label, className)} key={labelText}>
        {labelText}
        <span>{subText}</span>
      </div>
    )
  }

  const renderTotal = () => {
    if (!collectedTotal.length) {
      return 0
    }

    return collectedTotal.reduce(
      (acc, item) => acc + (item.note?.denomination ?? 0) * (item.count ?? 0),
      0
    )
  }

  const renderActionButton = (
    command: UserEntryCommandElement,
    className: string,
    disabled?: boolean
  ) => {
    const { objectId, elementData } = command
    const { text, icon } = elementData?.label ?? {}

    return (
      <Button
        disabled={!!disabled}
        variant="contained"
        className={classNames(styles.actionButtons, className)}
        onClick={() => sendMessage({ objectId })}
      >
        {text}
        {icon?.data}
      </Button>
    )
  }

  return (
    <div className={styles.block}>
      {title && <div className={styles.title}>{pageTitle}</div>}
      <div className={styles.content}>
        {!!Object.keys(labels).length && (
          <div className={styles.static}>
            {renderLabel(labels[Labels.LoanRepayment])}
            {renderLabel(labels[Labels.PleaseInsert])}
          </div>
        )}

        <div className={styles.fields}>
          <div className={styles.field}>
            <p className={styles.fieldTitle}>Принятая сумма</p>
            <span
              className={clsx(styles.fieldValue, styles.total)}
            >{`${renderTotal()} ${currency}`}</span>
          </div>
          {(table?.elementData as TableData).rows?.map((row, index) => (
            <div key={index} className={styles.field}>
              <p className={styles.fieldTitle}>{row.elements?.[0]}</p>
              <span className={styles.fieldValue}>{row.elements?.[1]}</span>
            </div>
          ))}
        </div>
        {renderLabel(labels[Labels.Attention], styles.attention)}
      </div>
      <div className={styles.buttonsContainer}>
        {cancelCommand?.elementData && renderActionButton(cancelCommand, styles.cancel)}
        {correctCommand?.elementData && (
          <div>{renderActionButton(correctCommand, styles.loadMore)}</div>
        )}
        {confirmCommand?.elementData &&
          renderActionButton(confirmCommand, styles.confirm, !isUserChoosing)}
      </div>
    </div>
  )
}
