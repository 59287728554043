import React, { FC } from 'react'

import { QrCodeSync } from '../../components/QrCodeSync'
import { SetLocale } from '../../components/SetLocale'
import { useStatusScreens } from '../../hooks/useStatusScreens'
import { UserEntryScreenData } from '../../swagger'
import { AtmUIProps } from '../../types'

import bs2Logo from './assets/bs2-logo.svg'
import { Content } from './components/Content'

import styles from './styles.module.css'

export const YellowScreen: FC<AtmUIProps> = props => {
  const {
    joinSecret,
    lang,
    setLang,
    serverMessage,
    commandPayload,
    deviceClass,
    setCommandPayload,
    sendMessage,
    validation
  } = props

  const { messageData, messageType, templateId } = serverMessage ?? {}

  const { confirmCommand, statusScreens } = (messageData ?? {}) as UserEntryScreenData

  const showStatusScreen = useStatusScreens({ statusScreens, confirmCommand })

  const contentMessageData = showStatusScreen ? Object.values(statusScreens ?? {})[0] : messageData

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <img src={bs2Logo} alt="bs2Logo" className={styles.logo} />

        <div className={styles.qrAndLang}>
          <QrCodeSync joinSecret={joinSecret} />
          <SetLocale selectedLang={lang} setLanguage={setLang} />
        </div>
      </div>
      <div className={styles.layout}>
        <Content
          templateId={templateId}
          messageType={messageType}
          messageData={contentMessageData as UserEntryScreenData}
          commandPayload={commandPayload}
          validation={validation}
          deviceClass={deviceClass}
          setCommandPayload={setCommandPayload}
          sendMessage={sendMessage}
        />
      </div>
    </div>
  )
}
