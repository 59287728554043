import { Element } from '../types'

export const elementConfig = (element: Element) => {
  const icon = element.elementData?.label?.icon?.data ?? ''
  const text = element.elementData?.label?.text ?? ''

  const { commandElementType, inputElementType, staticElementType } = element

  const elemType = commandElementType || inputElementType || staticElementType

  return {
    icon,
    text,
    elemType
  }
}
