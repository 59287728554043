import { FC, ReactNode } from 'react'
import classNames from 'classnames'

import { Loader } from '../Loader'

import styles from './styles.module.css'

interface Props {
  loading: boolean
  children: ReactNode
  circleStyle?: string
}

export const LoadingWrapper: FC<Props> = ({ loading, children, circleStyle }) => {
  if (loading) {
    return (
      <Loader
        classes={{ circle: classNames(styles.circle, circleStyle) }}
        className={styles.loader}
      />
    )
  }

  return <>{children}</>
}
