import { DeviceHardwareStatus } from '../swagger'

export const hwStatus: DeviceHardwareStatus = {
  cashIn: { acceptability: 'eAcceptOK' },
  cashOut: [
    { count: 100, currency: 'BEE', type: 4, value: 50 },
    { count: 100, currency: 'BEE', type: 4, value: 100 },
    { count: 100, currency: 'BEE', type: 4, value: 200 },
    { count: 100, currency: 'USD', type: 4, value: 50 },
    { count: 100, currency: 'USD', type: 4, value: 100 }
  ],
  receiptPrinter: 'ePrintOK'
}

export const cashInFeatures = {
  acceptableNotes: [
    { currency: 'BEE', denomination: 10 },
    { currency: 'BEE', denomination: 20 },
    { currency: 'BEE', denomination: 50 },
    { currency: 'EUR', denomination: 10 },
    { currency: 'EUR', denomination: 20 },
    { currency: 'EUR', denomination: 50 },
    { currency: 'XXX', denomination: 10 },
    { currency: 'XXX', denomination: 20 },
    { currency: 'XXX', denomination: 50 },
    { currency: 'KZT', denomination: 200 },
    { currency: 'KZT', denomination: 500 },
    { currency: 'KZT', denomination: 1000 },
    { currency: 'KZT', denomination: 2000 },
    { currency: 'KZT', denomination: 5000 },
    { currency: 'KZT', denomination: 10000 },
    { currency: 'KZT', denomination: 20000 }
  ]
}
