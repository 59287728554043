import { useEffect, useState } from 'react'

import { AuxStaticScreenData, UserEntryCommandElement } from '../swagger'

interface Props {
  statusScreens?: Record<string, AuxStaticScreenData>
  confirmCommand?: UserEntryCommandElement
}

export const useStatusScreens = ({ statusScreens, confirmCommand }: Props) => {
  const [showStatusScreen, setShowStatusScreen] = useState(false)

  useEffect(() => {
    if (Object.keys(statusScreens || {}).length && !confirmCommand) {
      const timer = window.setTimeout(() => {
        setShowStatusScreen(true)
      }, 1500)

      return () => {
        window.clearTimeout(timer)
        setShowStatusScreen(false)
      }
    }
  }, [statusScreens])

  return showStatusScreen
}
