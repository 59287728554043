import { memo, useState } from 'react'
import QRCode from 'react-qr-code'

import { ELEMENT_TYPES, ElementTypeInput } from '../../../../constants'
import { changePayload } from '../../../../helpers/utils'
import {
  CheckboxData,
  LabelData,
  ListData,
  QrData,
  ServerMessage,
  TableData,
  UserEntryCommandElement,
  UserEntryInputElement
} from '../../../../swagger'
import {
  CommandPayload,
  CommandType,
  HardwareButtonPosition,
  SendMessageProps
} from '../../../../types'
import { Element } from '../..'
import { Button } from '../Button'
import { List } from '../List'

import stylesInputs from '../../../GreenScreen/styles.module.css'
import styles from './styles.module.css'

type PaginationType = {
  currentPage: number
  pagesCount: number
  inputType: ElementTypeInput | null
}
interface Props {
  inputElements: UserEntryInputElement[]
  commandPayload: CommandPayload[]
  setCommandPayload: React.Dispatch<React.SetStateAction<CommandPayload[]>>
  sendMessage: ({ objectId, responseData }: SendMessageProps) => Promise<void>
  joinSecret: string
  serverMessage: ServerMessage | null
  confirmCommand?: UserEntryCommandElement
}

export const Inputs = memo((props: Props) => {
  const { commandPayload, setCommandPayload, inputElements, confirmCommand, sendMessage } = props

  const [pagination, setPagination] = useState<PaginationType>({
    currentPage: 1,
    pagesCount: commandPayload?.length || 0,
    inputType: commandPayload[0]?.inputElementType ?? null
  })

  const isLastPage = pagination.currentPage === pagination.pagesCount

  const handleChangePage = (modifier: number) => {
    setPagination(prevValue => {
      const newPage = prevValue.currentPage + modifier

      const isOutOfBounds =
        (modifier < 0 && newPage < 1) || (modifier > 0 && newPage > prevValue.pagesCount)

      return {
        ...prevValue,
        currentPage: isOutOfBounds ? prevValue.currentPage : newPage,
        inputType: commandPayload[newPage - 1]?.inputElementType ?? null
      }
    })
  }

  // todo create a common  component for groups and single input

  const getElement = (element: Element) => {
    const text = element.elementData?.label?.text ?? ''

    const payloadResult = commandPayload.find(({ objectId }) => objectId === element.objectId)
      ?.responseData

    const { commandElementType, inputElementType, staticElementType } = element

    const elemType = commandElementType || inputElementType || staticElementType

    switch (elemType) {
      case ELEMENT_TYPES.eButton:
        return (
          <Button
            value={<>{text}</>}
            position={(element?.position || 'r1') as HardwareButtonPosition}
            click={() => {
              sendMessage({ objectId: element.objectId })
            }}
          />
        )

      case ELEMENT_TYPES.eInput:
        return (
          <input
            autoFocus
            placeholder={text}
            value={payloadResult?.value}
            onChange={e => {
              setCommandPayload(
                changePayload({
                  objectId: element.objectId,
                  responseData: { value: e.target.value }
                })
              )
            }}
          />
        )

      case ELEMENT_TYPES.eCheckbox:
        return (
          <>
            <div> {text}</div>
            <Button
              value={(element.elementData as CheckboxData)?.selectionData?.offSelection?.text}
              position="l2"
              clearEvent={false}
              click={() => {
                if (!isLastPage) {
                  setCommandPayload(
                    changePayload({
                      objectId: element.objectId,
                      responseData: { value: false }
                    })
                  )
                  handleChangePage(1)
                } else {
                  sendMessage({
                    responseData: {
                      commandObjectId: 'btn:next',
                      inputElementsResponse: [
                        ...commandPayload,
                        {
                          inputElementType: elemType,
                          objectId: element.objectId,
                          responseData: { value: false }
                        }
                      ]
                    }
                  })
                }
              }}
            />
            <Button
              value={(element.elementData as CheckboxData)?.selectionData?.onSelection?.text}
              position="r2"
              clearEvent={false}
              click={() => {
                if (!isLastPage) {
                  setCommandPayload(
                    changePayload({
                      objectId: element.objectId,
                      responseData: { value: true }
                    })
                  )
                  handleChangePage(1)
                } else {
                  sendMessage({
                    responseData: {
                      commandObjectId: 'btn:next',
                      inputElementsResponse: [
                        ...commandPayload,
                        {
                          inputElementType: elemType,
                          objectId: element.objectId,
                          responseData: { value: true }
                        }
                      ]
                    }
                  })
                }
              }}
            />
          </>
        )

      case ELEMENT_TYPES.eList: {
        return (
          <List
            list={element.elementData as ListData}
            handleBack={pagination.currentPage > 1 ? () => handleChangePage(-1) : undefined}
            onSelect={item => {
              if (!isLastPage) {
                setCommandPayload(
                  changePayload({
                    objectId: element.objectId,
                    responseData: { values: [item.id] }
                  })
                )
                handleChangePage(1)
              } else {
                sendMessage({
                  responseData: {
                    commandObjectId: 'btn:next',
                    inputElementsResponse: [
                      ...commandPayload,
                      {
                        inputElementType: elemType,
                        objectId: element.objectId,
                        responseData: { values: [item.id] }
                      }
                    ]
                  }
                })
              }
            }}
          />
        )
      }

      case ELEMENT_TYPES.eQR: {
        const { text: qrText } = element?.elementData as QrData

        return (
          <div>
            <QRCode size={128} value={qrText ?? ''} />
          </div>
        )
      }

      case ELEMENT_TYPES.eLabel:
        return (
          <div className={stylesInputs.receivedText}>
            {(element.elementData as LabelData)?.text}
          </div>
        )
      case ELEMENT_TYPES.eTable:
        return (
          <table>
            <tbody>
              {(element?.elementData as TableData)?.rows?.map((row, index) => (
                <tr key={index}>
                  {row.elements?.map((el, rowIndex) => <td key={rowIndex}>{el}</td>)}
                </tr>
              ))}
            </tbody>
          </table>
        )

      default:
        return 'Unsupported elem'
    }
  }

  const inputsJSX = inputElements?.length
    ? inputElements.map(elem => {
        return getElement(elem as Element)
      })
    : []

  return (
    <div style={{ width: '100%' }}>
      {pagination.currentPage !== 1 && pagination.inputType !== 'eList' && (
        <Button value="<" position="l1" clearEvent={false} click={() => handleChangePage(-1)} />
      )}
      <div className={styles.inputWrap}>
        <span className={styles.steps}>
          {pagination.currentPage}/{pagination.pagesCount}
        </span>
        {inputsJSX[pagination.currentPage - 1]}
      </div>
      {!isLastPage && pagination.inputType !== 'eList' && pagination.inputType !== 'eCheckbox' && (
        <Button
          value=">"
          position="r1"
          clearEvent={false}
          click={() => {
            handleChangePage(1)
          }}
        />
      )}
      {isLastPage &&
        pagination.inputType !== 'eList' &&
        pagination.inputType !== 'eCheckbox' &&
        getElement({ ...confirmCommand, type: CommandType.confirmCommand } as Element)}
    </div>
  )
})
