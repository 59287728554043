import React from 'react'

import { ReactComponent as IcBack } from './ic_back.svg'
import { ReactComponent as IcCross } from './ic_cross.svg'
import { ReactComponent as IcTick } from './ic_tick.svg'

type IconMap = Record<
  string,
  React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string
    }
  >
>

export const commonIconsMap: IconMap = {
  'ic_tick': IcTick,
  'ic_cross': IcCross,
  'ic_back': IcBack,
}
