import { FC, useEffect } from 'react'

import { FaceRecognitionStartData } from '../../swagger'
import { SendMessageProps } from '../../types'
import { Loader } from '../Loader'

import { useSocket } from './hooks/useSocket'
import { getImageDescriptors } from './api'

import styles from './styles.module.css'

interface Props {
  messageData: FaceRecognitionStartData
  sendMessage: (props: SendMessageProps) => void
}

export const FaceRecognition: FC<Props> = ({ messageData, sendMessage }) => {
  const { bestShotAttempt = false } = messageData

  const { image, isLoading, connectWebSocket, closeConnection } = useSocket()

  useEffect(() => {
    connectWebSocket()
    getImageDescriptors({ onlyBestShot: bestShotAttempt }).then(responseData => {
      sendMessage({ responseData })
    })

    return () => {
      closeConnection && closeConnection()
    }
  }, [])

  return (
    <div className={styles.wrapper}>
      {isLoading && <Loader />}
      {image && (
        <img className={styles.image} src={`data:image/png;base64, ${image}`} alt="image" />
      )}
    </div>
  )
}
