import { ELEMENT_TYPES } from '../constants'
import {
  CheckboxData,
  InputData,
  ListData,
  NotesCollection,
  ServerMessage,
  UserEntryInputElement
} from '../swagger'
import { ChangePayloadProps, CommandPayload } from '../types'

interface ElementHandler {
  handle(element: UserEntryInputElement): CommandPayload | null
}

class InputHandler implements ElementHandler {
  handle(element: UserEntryInputElement): CommandPayload | null {
    const data = element.elementData as InputData

    if ('initialValue' in data) {
      return {
        isOptional: data.isOptional,
        inputElementType: element.inputElementType,
        objectId: element.objectId,
        responseData: { value: data.initialValue }
      }
    }
    return null
  }
}

class NumpadHandler implements ElementHandler {
  handle(element: UserEntryInputElement): CommandPayload | null {
    return {
      inputElementType: element.inputElementType,
      objectId: element.objectId,
      responseData: { value: '' }
    }
  }
}

class CheckboxHandler implements ElementHandler {
  handle(element: UserEntryInputElement): CommandPayload | null {
    const data = element.elementData as CheckboxData
    if ('checked' in data) {
      return {
        inputElementType: element.inputElementType,
        objectId: element.objectId,
        responseData: { checked: data.checked }
      }
    }
    return null
  }
}

class ListHandler implements ElementHandler {
  handle(element: UserEntryInputElement): CommandPayload | null {
    const value = (element.elementData as ListData)?.items?.[0]?.id || ''
    return {
      inputElementType: element.inputElementType,
      objectId: element.objectId,
      responseData: { values: [value] }
    }
  }
}

class AcceptHandler {
  handle(collectedSoFar: NotesCollection): CommandPayload | null {
    return {
      objectId: 'acceptId',
      responseData: {
        collectedCycle: [],
        collectedTotal: collectedSoFar || []
      }
    }
  }
}

const handlerMap = {
  [ELEMENT_TYPES.eInput]: new InputHandler(),
  [ELEMENT_TYPES.eNumpad]: new NumpadHandler(),
  [ELEMENT_TYPES.eCheckbox]: new CheckboxHandler(),
  [ELEMENT_TYPES.eList]: new ListHandler()
}

export const generateInitialPayload = (data: ServerMessage) => {
  const { messageData = {} } = data

  if ('inputElements' in messageData) {
    return (messageData.inputElements as UserEntryInputElement[])?.reduce((acc, element) => {
      if (element.inputElementType) {
        const handler = handlerMap[element.inputElementType]

        if (handler) {
          const payload = handler.handle(element)
          if (payload) {
            acc.push(payload)
          }
        }
      }
      return acc
    }, [] as CommandPayload[])
  }
  if ('collectedSoFar' in messageData) {
    const { collectedSoFar } = messageData
    const handler = new AcceptHandler()

    return [handler.handle(collectedSoFar || [])]
  }

  return []
}

export const changePayload =
  ({ responseData, objectId, ...rest }: ChangePayloadProps) =>
  (prevPayload: CommandPayload[]) => {
    return prevPayload.map(elem => {
      if (elem.objectId === objectId) {
        return {
          ...elem,
          ...rest,
          responseData
        }
      }
      return elem
    })
  }
