import { FC } from 'react'
import { ButtonBase } from '@mui/material'

import { changePayload } from '../../../../helpers/utils'
import { validateMask } from '../../../../helpers/validateMask'
import { Input } from '../Input'

import { NButtonProps, NumpadProps } from './types'

import styles from './styles.module.css'

const NButton: FC<NButtonProps> = props => {
  const { value, onClick } = props

  return (
    <ButtonBase onClick={() => onClick(value)} disableRipple className={styles.nButton}>
      <span>{value}</span>
    </ButtonBase>
  )
}

export const Numpad: FC<NumpadProps> = props => {
  const {
    input: { objectId, className, setCommandPayload, mask, value }
  } = props

  const keypadKeys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0']

  const handleButtonClick = (buttonValue: string) => {
    const newValue = value + buttonValue

    if (validateMask(newValue, mask)) {
      setCommandPayload(
        changePayload({
          objectId,
          responseData: { value: newValue }
        })
      )
    }
  }

  return (
    <div className={styles.numpadBox}>
      <Input
        mask={mask}
        value={value}
        className={className ?? ''}
        placeholder={mask}
        objectId={objectId as string}
        setCommandPayload={setCommandPayload}
      />
      <div className={styles.numpad}>
        {keypadKeys.map(keypadKey => (
          <NButton key={keypadKey} value={keypadKey} onClick={handleButtonClick} />
        ))}
      </div>
    </div>
  )
}
