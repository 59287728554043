import { useEffect } from 'react'

import { ServerMessage } from '../swagger'
import { SendMessageProps } from '../types'

interface Props {
  timeout?: number
  serverMessage: ServerMessage | null
  sendMessage: (value: SendMessageProps) => Promise<void>
}

export const useInactivityRefresh = (props: Props) => {
  const { timeout = 5 * 60 * 1000, serverMessage, sendMessage } = props

  const timer =
    serverMessage?.timeoutMs && serverMessage?.timeoutMs != -1 ? serverMessage?.timeoutMs : timeout

  useEffect(() => {
    if (serverMessage) {
      let inactivityTimer: ReturnType<typeof setTimeout>

      const setupInactivityTimer = (): void => {
        // Clear the existing timer if it's set
        if (inactivityTimer) {
          clearTimeout(inactivityTimer)
        }

        // Set the timer
        inactivityTimer = setTimeout(() => {
          sendMessage({ responseData: { code: 'eIdleTimeout' } })
        }, timer)
      }

      const resetInactivityTimer = (): void => {
        if (inactivityTimer) {
          clearTimeout(inactivityTimer)
        }
        setupInactivityTimer()
      }

      // Set up the inactivity timer initially
      setupInactivityTimer()

      // Reset the inactivity timer on various user actions
      const events = ['mousemove', 'keydown', 'touchstart', 'scroll']

      events.forEach(event => {
        window.addEventListener(event, resetInactivityTimer)
      })

      // Clean up the event listeners on component unmount
      return () => {
        events.forEach(event => {
          window.removeEventListener(event, resetInactivityTimer)
        })

        if (inactivityTimer) {
          clearTimeout(inactivityTimer)
        }
      }
    }
  }, [timeout, serverMessage])
}
