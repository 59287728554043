import { toast } from 'react-toastify'
import axios from 'axios'
import { AxiosError } from 'axios'

export const handleError = (error: AxiosError): void => {
  if (axios.isCancel(error)) {
    console.log('Request canceled:', (error as { message: string }).message)
    return
  }
  if (axios.isAxiosError(error)) {
    const { response, message } = error as AxiosError

    // Access to config, request, and response
    toast.error(typeof response?.data === 'string' ? String(response?.data || '') : message)

    if (response?.status === 400 && response.data === 'Unauthorized') {
      localStorage.removeItem('token')
      window.location.reload()
    }
  }
  throw error
}
