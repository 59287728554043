import { UserEntryCommandElement } from '../../../swagger'
import { CommandType } from '../../../types'

export const HARDWARE_BUTTON_COUNT = 8

export const useHardwareButtons = () => {
  const middleIndex = Math.floor(HARDWARE_BUTTON_COUNT / 2)
  const positions: string[] = Array.from(Array(HARDWARE_BUTTON_COUNT)).reduce((acc, _, index) => {
    const side = index < middleIndex ? 'r' : 'l'

    return [...acc, `${side}${index + 1 - (index < middleIndex ? 0 : middleIndex)}`]
  }, [])

  const renderButton = () => {
    const leftButtons = Array.from(Array(HARDWARE_BUTTON_COUNT - middleIndex))
      .map((_, index) => {
        const id = `l${index + 1}`

        return (
          <button key={id} id={id} className="hardware-button">
            {id}
          </button>
        )
      })
      .reverse()
    const rightButtons = Array.from(Array(middleIndex))
      .map((_, index) => {
        const id = `r${index + 1}`

        return (
          <button key={id} id={id} className="hardware-button">
            {id}
          </button>
        )
      })
      .reverse()

    return { leftButtons, rightButtons }
  }

  const assignPosition = (element: UserEntryCommandElement) => {
    if (element?.type === CommandType.confirmCommand) {
      positions.splice(positions.indexOf('r1'), 1)
      return { ...element, position: 'r1' }
    }
    if (element?.type === CommandType.confirmCommand) {
      positions.splice(positions.indexOf('r2'), 1)
      return { ...element, position: 'r2' }
    }
    if (element?.type === CommandType.cancelCommand) {
      positions.splice(positions.indexOf('l1'), 1)
      return { ...element, position: 'l1' }
    }

    return { ...element, position: positions.shift() }
  }

  return { positions, renderButton, assignPosition }
}
