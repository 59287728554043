import React, { ChangeEvent, FC } from 'react'
import BackspaceIcon from '@mui/icons-material/Backspace'
import classNames from 'classnames'

import { changePayload } from '../../../../helpers/utils'
import { validateMask } from '../../../../helpers/validateMask'

import { Props } from './types'

import styles from './styles.module.css'

export const Input: FC<Props> = props => {
  const { placeholder, value, objectId, className, isOptional, setCommandPayload, mask } = props

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value

    validateMask(inputValue, mask) &&
      setCommandPayload(
        changePayload({
          objectId,
          isOptional,
          responseData: { value: inputValue }
        })
      )
  }

  const backSpaceClick = () => {
    setCommandPayload(
      changePayload({
        objectId,
        isOptional,
        responseData: { value: value.slice(0, -1) }
      })
    )
  }

  return (
    <div className={styles.inputWrap}>
      <input
        placeholder={placeholder}
        value={value}
        className={classNames(styles.input, className)}
        onChange={onChange}
      />
      <BackspaceIcon className={styles.backspace} onClick={backSpaceClick} />
    </div>
  )
}
