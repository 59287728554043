import React, { FC, useMemo } from 'react'
import QRCode from 'react-qr-code'

import { PageTitle } from '../../components/PageTitle'
import { Receipt } from '../../components/Receipt'
import { Timeout } from '../../components/TimeoutCountdown'
import { DEVICE_CLASSES, ELEMENT_TYPES, MESSAGE_TYPE } from '../../constants'
import { changePayload } from '../../helpers/utils'
import {
  AcceptData,
  CheckboxData,
  DispenseData,
  LabelData,
  ListData,
  QrData,
  ReceiptData,
  TableData,
  UserEntryCommandElement,
  UserEntryInputElement,
  UserEntryScreenData
} from '../../swagger'
import {
  AtmUIProps,
  CommandType,
  Element as BaseElement,
  HardwareButtonPosition
} from '../../types'

import { Accept } from './components/Accept'
import { Button } from './components/Button'
import { HardwareButtons } from './components/HardwareButtons'
import { Inputs } from './components/Inputs'
import { List } from './components/List'
import { useHardwareButtons } from './hooks/useHardwareButtons'

import styles from './styles.module.css'

export type Element = BaseElement & { position?: HardwareButtonPosition; type?: CommandType }

export const GreenScreen: FC<AtmUIProps> = ({
  serverMessage,
  commandPayload,
  deviceClass,
  joinSecret,
  validation,
  setCommandPayload,
  sendMessage
}) => {
  const { isValid } = validation

  const { assignPosition } = useHardwareButtons()

  const { messageData, messageType } = serverMessage ?? {}
  const {
    title,
    staticElements,
    cancelCommand,
    correctCommand,
    confirmCommand,
    auxCommandElements,
    inputElements
  } = (messageData || {}) as UserEntryScreenData

  // todo to merge in common for single and groups
  const getElement = (element: Element) => {
    const text = element.elementData?.label?.text ?? ''

    const payloadResult = commandPayload.find(({ objectId }) => objectId === element.objectId)
      ?.responseData

    const { commandElementType, inputElementType, staticElementType } = element

    const elemType = commandElementType || inputElementType || staticElementType

    switch (elemType) {
      case ELEMENT_TYPES.eButton:
        return (
          <Button
            disabled={element.type === CommandType.confirmCommand && isValid}
            value={<>{text}</>}
            position={(element?.position ?? 'r1') as HardwareButtonPosition}
            click={() => {
              sendMessage({ objectId: element.objectId })
            }}
          />
        )

      case ELEMENT_TYPES.eInput:
        return (
          <input
            autoFocus
            placeholder={text}
            value={payloadResult?.value}
            onChange={e => {
              setCommandPayload(
                changePayload({
                  objectId: element.objectId,
                  responseData: { value: e.target.value }
                })
              )
            }}
          />
        )

      case ELEMENT_TYPES.eCheckbox:
        return (
          <>
            <div> {text}</div>
            <Button
              value={(element.elementData as CheckboxData)?.selectionData?.offSelection?.text}
              position="l2"
              clearEvent={false}
              click={() => {
                sendMessage({
                  responseData: {
                    commandObjectId: confirmCommand?.objectId,
                    inputElementsResponse: [
                      {
                        inputElementType: elemType,
                        objectId: element.objectId,
                        responseData: { value: false }
                      }
                    ]
                  }
                })
              }}
            />
            <Button
              value={(element.elementData as CheckboxData)?.selectionData?.onSelection?.text}
              position="r2"
              clearEvent={false}
              click={() => {
                sendMessage({
                  responseData: {
                    commandObjectId: confirmCommand?.objectId,
                    inputElementsResponse: [
                      {
                        inputElementType: elemType,
                        objectId: element.objectId,
                        responseData: { value: true }
                      }
                    ]
                  }
                })
              }}
            />
          </>
        )

      case ELEMENT_TYPES.eList: {
        return (
          <List
            list={element.elementData as ListData}
            onSelect={item => {
              sendMessage({
                responseData: {
                  commandObjectId: confirmCommand?.objectId,
                  inputElementsResponse: [
                    {
                      inputElementType: elemType,
                      objectId: element.objectId,
                      responseData: { values: [item.id] }
                    }
                  ]
                }
              })
            }}
          />
        )
      }

      case ELEMENT_TYPES.eQR: {
        const { text: qrText } = element?.elementData as QrData

        return (
          <div className="qr">
            <QRCode size={128} value={qrText ?? ''} />
          </div>
        )
      }

      case ELEMENT_TYPES.eLabel:
        return <div className={styles.receivedText}>{(element.elementData as LabelData)?.text}</div>
      case ELEMENT_TYPES.eTable:
        return (
          <table>
            <tbody>
              {(element?.elementData as TableData)?.rows?.map((row, index) => (
                <tr key={index}>
                  {row.elements?.map((el, rowIndex) => <td key={rowIndex}>{el}</td>)}
                </tr>
              ))}
            </tbody>
          </table>
        )

      default:
        return 'Unsupported elem'
    }
  }

  // todo change for separately logic
  let inputElement = [...(inputElements ?? [])]

  const inputsGroup = useMemo(() => {
    return inputElement?.filter(element => element.inputElementType === ELEMENT_TYPES.eInput)
  }, [inputElement])

  if ((inputsGroup?.length || 0) > 1) {
    inputElement =
      inputElements?.filter(element => element.inputElementType !== ELEMENT_TYPES.eInput) ?? []
    if (inputsGroup.length > 1) {
      inputElement.push(inputsGroup[0])
    }
  }

  const allElements = [...(auxCommandElements ?? []), ...(inputElement || [])]

  const isList = inputElement?.some(element => element.inputElementType === ELEMENT_TYPES.eList)

  if (deviceClass === DEVICE_CLASSES.eDeviceClassKiosk && !isList) {
    if (cancelCommand) {
      allElements.unshift({
        ...cancelCommand,
        type: CommandType.cancelCommand
      } as UserEntryCommandElement)
    }
    if (correctCommand) {
      allElements.unshift({
        ...correctCommand,
        type: CommandType.correctCommand
      } as UserEntryCommandElement)
    }
    if (confirmCommand) {
      allElements.unshift({
        ...confirmCommand,
        type: CommandType.confirmCommand
      } as UserEntryCommandElement)
    }
  }

  const elementsContent = allElements.reduce(
    (acc, elem) => {
      const isUserEntryCommandElement = 'commandElementType' in elem
      const processedElement = getElement(
        (isUserEntryCommandElement
          ? assignPosition(elem as UserEntryCommandElement)
          : elem) as Element
      )

      if (isUserEntryCommandElement) {
        acc.buttons.push(processedElement)
      } else {
        acc.content.push(processedElement)
      }

      return acc
    },
    {
      buttons: [] as Array<string | React.JSX.Element>,
      content: [] as Array<string | React.JSX.Element>
    }
  )

  const contentJSX =
    inputsGroup?.length > 1 ? (
      <Inputs
        serverMessage={serverMessage}
        inputElements={inputElements as Array<UserEntryInputElement>}
        commandPayload={commandPayload}
        setCommandPayload={setCommandPayload}
        sendMessage={sendMessage}
        joinSecret={joinSecret}
        confirmCommand={confirmCommand}
      />
    ) : (
      elementsContent.content
    )

  const staticElementsJSX = staticElements?.map(elem => {
    return getElement(elem as Element)
  })

  return (
    <HardwareButtons>
      <div className={styles.atmWrapper}>
        {title && <PageTitle title={title} className={styles.atmTitle} />}

        {messageType === MESSAGE_TYPE.eAccept && (
          <Accept messageData={messageData as AcceptData} sendMessage={sendMessage} />
        )}
        {messageType === MESSAGE_TYPE.eDispense && (
          <>
            <div>Total:</div>
            <div className={styles.receivedText}>
              {/* TODO: This is ugly */}
              {((messageData as DispenseData).notesToDispense?.[0]?.note?.denomination ?? 0) *
                ((messageData as DispenseData).notesToDispense?.[0]?.count ?? 0)}
              {(messageData as DispenseData).notesToDispense?.[0]?.note?.currency}
            </div>
          </>
        )}

        {messageType === MESSAGE_TYPE.eReceipt && (
          <div style={{ textAlign: 'center' }}>
            <div>Take receipt</div>
            <Receipt messageData={messageData as ReceiptData} />
          </div>
        )}

        {staticElementsJSX}
        {contentJSX}
        <Timeout />

        <div className={styles.buttonsContainer}>{elementsContent.buttons}</div>
      </div>
    </HardwareButtons>
  )
}
