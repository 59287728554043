import { useEffect, useState } from 'react'

import { CommandPayload } from '../types'
type ValidationTypes = {
  commandPayload: Array<CommandPayload>
}
export const useInputValidation = ({ commandPayload }: ValidationTypes) => {
  const [isValid, setIsValid] = useState(true)

  const hasValueLength = (el: CommandPayload) => el.responseData?.value?.length
  const hasListLength = (el: CommandPayload) => el.responseData?.values?.length

  const eInputValidation = (input: CommandPayload) => {
    if (input.isOptional) {
      return true
    }
    return !!input.responseData?.value?.length
  }

  // Validation rule can be used from commandPayload with response data from server
  const validators = {
    eInput: eInputValidation,
    eNumpad: hasValueLength,
    eList: hasListLength,
    eCheckbox: () => true,
    defaultValidator: () => true
  }

  useEffect(() => {
    if (commandPayload?.length) {
      setIsValid(
        !commandPayload.every(el => {
          const validate =
            (el.inputElementType && validators[el.inputElementType]) || validators.defaultValidator
          return validate(el)
        })
      )
    } else {
      setIsValid(false)
    }
  }, [commandPayload])

  return { isValid }
}
