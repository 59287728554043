import clsx from 'clsx'

import { AcceptProps, useAccept } from '../../../../hooks/useAcceptCash'

import styles from './styles.module.css'

export const Accept = (props: AcceptProps) => {
  const { acceptableNotes, collectedTotal, limits } = useAccept(props)

  return (
    <div className={styles.wrapper}>
      <div className={styles.info}>
        <div>
          <div className={styles.block}>
            <p className={styles.subTitle}>Max amount:</p>
            <div className={styles.receivedText}>{limits?.max_amount}</div>
          </div>

          <div className={styles.block}>
            <p className={styles.subTitle}>Acceptable notes:</p>
            {acceptableNotes?.map(({ currency, denomination }) => (
              <div key={denomination} className={styles.receivedText}>
                {denomination} {currency}
              </div>
            ))}
          </div>
        </div>
        <div>
          <div className={styles.block}>
            <p className={styles.subTitle}>Collected:</p>
            {collectedTotal.map(item => (
              <div key={item.note?.denomination} className={styles.receivedText}>
                ({item.count}) {item.note?.denomination} {item.note?.currency}
              </div>
            ))}
          </div>
          {!!collectedTotal.length && (
            <div className={clsx(styles.receivedText, styles.total)}>
              {collectedTotal.reduce(
                (acc, item) => acc + (item.note?.denomination || 0) * (item.count || 0),
                0
              )}{' '}
              {collectedTotal[0].note?.currency}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
