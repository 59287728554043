import { AxiosRequestConfig, AxiosRequestHeaders } from 'axios'

import { DeviceMessage, SessionStartData } from '../swagger'

import { initClient } from './client'

const getHeader = (headers?: AxiosRequestHeaders) => {
  const newHeader = { ...(headers ?? {}) }
  const token = localStorage.getItem('token')
  const key = localStorage.getItem('key')

  token ? (newHeader.Authorization = `Bearer ${token}`) : (newHeader['X-API-KEY'] = key)

  return newHeader
}

interface RequestProps<T = {}> {
  payload?: T
  apiUrl: string
  axiosConfig?: AxiosRequestConfig
}

export const startSessionRequest = async (props: RequestProps<SessionStartData>) => {
  const { payload = {}, apiUrl, axiosConfig } = props

  const _client = initClient(apiUrl)
  return _client.post('/start-session', payload, { ...axiosConfig, headers: getHeader() })
}

export const sendSessionMessage = async (props: RequestProps<DeviceMessage>) => {
  const { payload = {}, apiUrl, axiosConfig } = props

  const _client = initClient(apiUrl)
  return _client.post('/session-message', payload, { ...axiosConfig, headers: getHeader() })
}

export const sessionPoll = async (props: RequestProps) => {
  const { payload = {}, apiUrl, axiosConfig } = props

  const _client = initClient(apiUrl)
  return _client.post('/session-poll', payload, { ...axiosConfig, headers: getHeader() })
}

export const sessionSync = async (props: RequestProps) => {
  const { payload = {}, apiUrl, axiosConfig } = props

  const _client = initClient(apiUrl)
  return _client.post('/session-sync', payload, { ...axiosConfig, headers: getHeader() })
}
