import { FC } from 'react'
import QRCode from 'react-qr-code'
import { Button } from '@mui/material'
import classNames from 'classnames'

import { commonIconsMap } from '../../assets/icons/icons.map'
import { HTMLContent } from '../../components/HTMLContent'
import { PageTitle } from '../../components/PageTitle'
import { DEVICE_CLASSES, ELEMENT_TYPES } from '../../constants'
import { elementConfig } from '../../helpers/elementConfig'
import { HtmlData, QrData, UserEntryCommandElement } from '../../swagger'
import { Element } from '../../types'
import { BaseTemplateProps } from '../../types/templates'

import styles from './styles.module.css'

export const ReceiptWithQr: FC<BaseTemplateProps> = ({ deviceClass, messageData, sendMessage }) => {
  const { title, staticElements, cancelCommand, correctCommand, confirmCommand } = messageData || {}

  const getElement = (element: Element) => {
    const { elemType } = elementConfig(element)

    switch (elemType) {
      case ELEMENT_TYPES.eQR: {
        const { text: qrText } = element?.elementData as QrData

        return (
          <div className={`qr ${styles.qr}`}>
            <QRCode size={128} value={qrText ?? ''} />
          </div>
        )
      }
      case ELEMENT_TYPES.eHtml:
        return (
          <HTMLContent html={(element.elementData as HtmlData).content} className={styles.html} />
        )
      default:
        return `Unsupported elem, type: ${elemType}`
    }
  }

  const renderActionButton = (
    command: UserEntryCommandElement,
    className: string,
    disabled?: boolean
  ) => {
    const { objectId, elementData } = command
    const { text, icon } = elementData?.label || {}

    const IconSVG = icon?.data ? commonIconsMap[icon.data] : null

    return (
      <Button
        disabled={!!disabled}
        variant="contained"
        className={classNames(styles.actionButtons, className)}
        onClick={() => sendMessage({ objectId })}
      >
        {IconSVG ? <IconSVG style={{ width: 18, height: 'auto', marginRight: 10 }} /> : null}
        {text}
      </Button>
    )
  }

  return (
    <div className={styles.content}>
      <div className={styles.block}>
        {title && <PageTitle title={title} />}
        <div className={styles.controlContent}>
          {staticElements && (
            <div className={styles.static}>
              {staticElements.map(element => {
                return getElement(element as Element)
              })}
            </div>
          )}
        </div>
      </div>

      {deviceClass === DEVICE_CLASSES.eDeviceClassKiosk && (
        <div className={styles.buttonsContainer}>
          {cancelCommand?.elementData && renderActionButton(cancelCommand, styles.cancel)}
          {correctCommand?.elementData && renderActionButton(correctCommand, styles.correct)}
          {confirmCommand?.elementData && renderActionButton(confirmCommand, styles.confirm)}
        </div>
      )}
    </div>
  )
}
