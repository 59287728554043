import { FC, ReactNode } from 'react'

import { useHardwareButtons } from '../../hooks/useHardwareButtons'

import styles from './styles.module.css'

interface Props {
  children: ReactNode
}

export const HardwareButtons: FC<Props> = props => {
  const { children } = props

  const { renderButton } = useHardwareButtons()
  const { leftButtons, rightButtons } = renderButton()

  return (
    <div id="atm-interface" className={styles.atmInterface}>
      {leftButtons.length > 0 && <div className={styles.hardwareButtons}>{leftButtons}</div>}
      {children}
      {rightButtons.length > 0 && <div className={styles.hardwareButtons}>{rightButtons}</div>}
    </div>
  )
}
