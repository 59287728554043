import { FC } from 'react'

import { ReceiptData } from '../../swagger'

import styles from './styles.module.css'

interface Props {
  messageData: ReceiptData
}

export const Receipt: FC<Props> = props => {
  const { messageData } = props
  const { content, type } = messageData || {}

  if (!content) {
    return 'No receipt content'
  }

  const renderContent = () => {
    if (content) {
      return type === 'text' ? content : <div dangerouslySetInnerHTML={{ __html: content }} />
    }

    return 'No data'
  }

  return (
    <div className={styles.receipt}>
      {renderContent()}
      <div className={styles.receiptBottom} />
    </div>
  )
}
