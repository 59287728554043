import React from 'react'
import { createRoot } from 'react-dom/client'
import { StyledEngineProvider } from '@mui/material'

import App from './App'

declare global {
  interface Window {
    GLOBAL_ENV?: {
      REACT_APP_PY_URL: string
      REACT_APP_NG_URL: string
      REACT_APP_HARD_WARE_SERVICE: string
    }
  }
}

const root = createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <App />
    </StyledEngineProvider>
  </React.StrictMode>
)
