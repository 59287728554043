import { createContext, useEffect, useMemo, useRef, useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { Button } from '@mui/material'

import { sendSessionMessage, sessionSync, startSessionRequest } from './api/emulatorService'
import { LoadMoneyControl } from './components/LoadMoneyControl'
import { Scenarios } from './components/Scenarios'
import { Settings } from './components/Settings'
import { cashInFeatures, hwStatus } from './constants/startSession'
import { generateJoinSecret } from './helpers/generateJoinSecret'
import { hwInterceptors } from './helpers/interceptors'
import { generateInitialPayload } from './helpers/utils'
import { AcceptCashResponse, useAcceptCash } from './hooks/useAcceptCash'
import { useEmulatorSettings } from './hooks/useEmulatorSettings'
import { useInactivityRefresh } from './hooks/useInactivityRefresh'
import { useInputValidation } from './hooks/useInputValidation'
import { useLongPolling } from './hooks/useLongPolling'
import { DEVICE_CLASSES, LANGUAGES_LIST, MESSAGE_TYPE } from './constants'
import { EurasianBank, GreenScreen, YellowScreen } from './screens'
import {
  AcceptData,
  AcceptResponse,
  API_VERSION,
  DeviceMessage,
  Lang,
  ServerMessage,
  UserEntryScreenData
} from './swagger'
import { CommandPayload, EmulatorSetting, SendMessageProps, TimeoutCountdown } from './types'

import 'react-toastify/dist/ReactToastify.css'
import './index.css'

const defaultLang = LANGUAGES_LIST[0].key

export const SCREEN_LIST = [
  {
    name: 'Eurasian Bank',
    value: EurasianBank,
    askLang: false
  },
  {
    name: 'Standard',
    value: GreenScreen,
    askLang: true
  },
  {
    name: 'Plate',
    value: YellowScreen,
    askLang: false
  }
]

interface ContextProps {
  timeout: TimeoutCountdown
  acceptCash: AcceptCashResponse
}

export const Context = createContext<ContextProps>({} as ContextProps)

const AppRoutes = () => {
  const { emulatorSettings, handleEmulatorSettings } = useEmulatorSettings()

  const apiUrl = emulatorSettings[EmulatorSetting.useSecondaryUrl]
    ? emulatorSettings[EmulatorSetting.apiUrlCS]
    : emulatorSettings[EmulatorSetting.apiUrl]

  const [lang, setLang] = useState(defaultLang)
  const [inputValue, setInputValue] = useState('')

  const [serverMessage, setServerMessage] = useState<ServerMessage | null>(null)
  const [commandPayload, setCommandPayload] = useState<CommandPayload[]>([])

  const [showTimer, setShowTimer] = useState(false)
  const [countdown, setCountdown] = useState<number>(5)

  const [messageLoading, setMessageLoading] = useState(false)

  const joinSecret = useRef<string | null>(null)

  const { isValid } = useInputValidation({ commandPayload })

  const acceptCash = useAcceptCash({
    messageData: serverMessage?.messageData as AcceptData,
    commandPayload,
    setCommandPayload
  })

  const resetAll = () => {
    setInputValue('')
    setServerMessage(null)
    setCommandPayload([])
    setLang(defaultLang)
  }

  const [isAcceptLoading, setIsAcceptLoading] = useState(false)

  const processResponse = async (data: ServerMessage) => {
    setCommandPayload(generateInitialPayload(data) as CommandPayload[])
    setServerMessage(data)
    setMessageLoading(false)

    if (emulatorSettings[EmulatorSetting.isHardwareWebService]) {
      hwInterceptors({
        data,
        setCommandPayload,
        setIsAcceptLoading,
        apiUrl
      })
    }
  }

  const { startSubscribeSync, stopSubscribeSync } = useLongPolling({
    emulatorSettings,
    processResponse,
    defaultLang: lang
  })

  const { AtmUi, askLang } = useMemo(() => {
    const { askLang: ask, value } = SCREEN_LIST[emulatorSettings[EmulatorSetting.atmUIIndex]]

    return {
      AtmUi: value,
      askLang: ask
    }
  }, [emulatorSettings])

  const startSession = async () => {
    setMessageLoading(true)
    try {
      stopSubscribeSync()

      joinSecret.current = generateJoinSecret()

      const { data } = await startSessionRequest({
        payload: {
          clientLanguage: !askLang ? (lang as Lang) : undefined,
          joinSecret: joinSecret.current || '',
          sceneId: emulatorSettings[EmulatorSetting.sceneId] || '',
          deviceClass: emulatorSettings[EmulatorSetting.deviceClass],
          hwStatus: hwStatus,
          info: {
            protocolVersion: API_VERSION,
            terminalId: emulatorSettings[EmulatorSetting.terminalId],
            cashInFeatures: cashInFeatures
          }
        },
        apiUrl
      })
      await processResponse(data)
      // Start a new subscribeSync
      startSubscribeSync(data)
    } catch (error) {
      setMessageLoading(false)
    }
  }

  const sendMessage = async ({ objectId, responseData }: SendMessageProps) => {
    setMessageLoading(true)
    stopSubscribeSync()
    const { sessionId, messageId, messageType } = serverMessage ?? {}

    const base = {
      sessionId,
      messageId,
      clientLanguage: lang as Lang,
      sceneId: emulatorSettings[EmulatorSetting.sceneId] ?? undefined,
      deviceClass: emulatorSettings[EmulatorSetting.deviceClass]
    }

    let _responseData = responseData

    if (!responseData) {
      _responseData = {
        inputElementsResponse: commandPayload,
        commandObjectId: objectId
      }
    }

    if (messageType === MESSAGE_TYPE.eAccept && objectId) {
      _responseData = {
        ...commandPayload[0].responseData,
        userReaction: (serverMessage?.messageData as AcceptData).userReactionButtonMap?.[objectId]
      } as AcceptResponse
    }

    const message = {
      ...base,
      messageType,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      responseData: _responseData as { [key: string]: any }
    } as DeviceMessage

    try {
      const { data } = await sendSessionMessage({
        payload: message,
        apiUrl
      })

      // todo temp solution
      if (askLang && data?.recommendedLanguage) {
        setLang(data?.recommendedLanguage)
      }

      await processResponse(data)
      // Start a new subscribeSync
      startSubscribeSync(data)
    } catch (e) {
      setMessageLoading(false)
      try {
        const { data } = await sessionSync({
          payload: base,
          apiUrl: emulatorSettings.useSecondaryUrl
            ? emulatorSettings.apiUrlCS
            : emulatorSettings.apiUrl
        })
        await processResponse(data)

        startSubscribeSync(data)
      } catch (error) {
        setMessageLoading(false)
        handleEmulatorSettings({ [EmulatorSetting.sceneId]: null })
        resetAll()
      }
    }
  }

  const hardwareSimulate = async (field: string, value?: unknown) => {
    const responseData = {
      [field]: value || inputValue
    }
    sendMessage({ responseData })
  }

  useEffect(() => {
    if (emulatorSettings[EmulatorSetting.sceneId]) {
      startSession()
    }
  }, [emulatorSettings[EmulatorSetting.sceneId], emulatorSettings[EmulatorSetting.deviceClass]])

  useEffect(() => {
    let intervalId: NodeJS.Timeout | undefined = undefined

    if (serverMessage?.messageType === MESSAGE_TYPE.eSessionComplete) {
      setShowTimer(true)
      intervalId = setInterval(() => {
        setCountdown(prevCountdown => {
          if (prevCountdown <= -1) {
            clearInterval(intervalId)
            window.location.reload()
            setShowTimer(false)
            return 0
          }
          return prevCountdown - 1
        })
      }, 1000)
    } else {
      setCountdown(5)
      setShowTimer(false)
      clearInterval(intervalId)
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
  }, [serverMessage?.messageType])

  useInactivityRefresh({ serverMessage, sendMessage })

  const updateLang = async (_lang: string) => {
    const { sessionId, messageId } = serverMessage ?? {}

    setLang(_lang)

    const pollRequest = {
      sessionId,
      messageId,
      clientLanguage: _lang,
      sceneId: emulatorSettings.sceneId,
      deviceClass: emulatorSettings.deviceClass
    }

    const { data } = await sessionSync({
      payload: pollRequest,
      apiUrl: emulatorSettings.useSecondaryUrl ? emulatorSettings.apiUrlCS : emulatorSettings.apiUrl
    })
    await processResponse(data)
  }

  return (
    <Context.Provider
      value={{
        acceptCash,
        timeout: {
          showTimer,
          countdown
        }
      }}
    >
      <div className="pageWrapper">
        <Settings
          emulatorSettings={emulatorSettings}
          handleEmulatorSettings={handleEmulatorSettings}
        />
        {!emulatorSettings[EmulatorSetting.sceneId] ? (
          <Scenarios handleEmulatorSettings={handleEmulatorSettings} />
        ) : (
          <>
            <AtmUi
              lang={lang}
              joinSecret={`${joinSecret.current};${emulatorSettings[EmulatorSetting.sceneId]}`}
              setLang={updateLang}
              commandPayload={commandPayload}
              setCommandPayload={setCommandPayload}
              serverMessage={serverMessage}
              deviceClass={emulatorSettings[EmulatorSetting.deviceClass]}
              sendMessage={sendMessage}
              messageLoading={messageLoading}
              validation={{
                isValid,
                isAcceptLoading
              }}
            />

            <div className="hardWareControls">
              <button
                type="button"
                onClick={() => {
                  sendMessage({
                    responseData: { text: ':(', code: 'eFatalHardwareError' }
                  })
                }}
              >
                Error
              </button>

              {serverMessage?.messageType === MESSAGE_TYPE.eAccept && (
                <LoadMoneyControl
                  isLoading={isAcceptLoading}
                  isHardwareWebService={emulatorSettings[EmulatorSetting.isHardwareWebService]}
                />
              )}
              {serverMessage?.messageType === MESSAGE_TYPE.eAcceptApprove && (
                <button
                  type="button"
                  onClick={() => {
                    hardwareSimulate('collected')
                  }}
                >
                  Accept Finish
                </button>
              )}
              {serverMessage?.messageType === MESSAGE_TYPE.eReceipt && (
                <button
                  type="button"
                  onClick={() => {
                    hardwareSimulate('printed', true)
                  }}
                >
                  Receipt Finish
                </button>
              )}
              {serverMessage?.messageType === MESSAGE_TYPE.eDispense && (
                <button
                  type="button"
                  onClick={() => {
                    hardwareSimulate('collectedSum')
                  }}
                >
                  Dispense Finish
                </button>
              )}

              {emulatorSettings[EmulatorSetting.deviceClass] !==
                DEVICE_CLASSES.eDeviceClassKiosk && (
                <>
                  <Button
                    disabled={
                      !(serverMessage?.messageData as UserEntryScreenData)?.cancelCommand?.objectId
                    }
                    variant="outlined"
                    onClick={async () => {
                      sendMessage({
                        objectId: (serverMessage?.messageData as UserEntryScreenData)?.cancelCommand
                          ?.objectId
                      })
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    disabled={
                      !(serverMessage?.messageData as UserEntryScreenData)?.confirmCommand
                        ?.objectId || isValid
                    }
                    variant="outlined"
                    onClick={async () => {
                      sendMessage({
                        objectId: (serverMessage?.messageData as UserEntryScreenData)
                          ?.confirmCommand?.objectId
                      })
                    }}
                  >
                    Confirm
                  </Button>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </Context.Provider>
  )
}

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<AppRoutes />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer role="" />
    </>
  )
}

export default App
