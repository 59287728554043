import React from 'react'
import { CircularProgress } from '@mui/material'
import classnames from 'classnames'

import { Props } from './types'

import styles from './styles.module.css'

export const Loader: React.FC<Props> = (props: Props): React.ReactElement => {
  const { className, inline = false, ...restProps } = props

  return (
    <>
      <CircularProgress
        classes={{ circle: styles.circle }}
        className={classnames(!inline ? styles.loader : styles.inline, className)}
        {...restProps}
      />
    </>
  )
}
