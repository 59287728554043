import { FC, useEffect, useState } from 'react'

import { calculatePagesCount } from '../../../../helpers/calculatePagesCount'
import { ListData, ListEntry } from '../../../../swagger'
import { HardwareButtonPosition } from '../../../../types'
import { HARDWARE_BUTTON_COUNT, useHardwareButtons } from '../../hooks/useHardwareButtons'
import { Button } from '../Button'

interface Props {
  list: ListData
  handleBack?: () => void
  onSelect: (item: ListEntry) => void
}

const divideArray = (arr: ListEntry[], size: number): ListEntry[][] => {
  const result: ListEntry[][] = []

  for (let i = 0; i < arr.length; i += size) {
    result.push(arr.slice(i, i + size))
  }

  return result
}

export const List: FC<Props> = props => {
  const {
    list: { items },
    onSelect,
    handleBack
  } = props
  const { positions } = useHardwareButtons()

  const [selectedPage, setSelectedPage] = useState(0)

  const listItemLength = items?.length || 0

  const elementPerPage = HARDWARE_BUTTON_COUNT - (listItemLength <= HARDWARE_BUTTON_COUNT ? 0 : 2)

  const pagesCount = calculatePagesCount(elementPerPage, listItemLength)
  const dividedList = divideArray(items || [], elementPerPage)

  useEffect(() => {
    setSelectedPage(0)
  }, [items])

  const assignPosition = (list: ListEntry[]) => {
    const pos = [...positions]
    if (listItemLength > HARDWARE_BUTTON_COUNT) {
      pos.splice(pos.indexOf('r1'), 1)
      pos.splice(pos.indexOf('l1'), 1)
    }
    return list.map(item => ({ ...item, position: pos.shift() }))
  }

  const positionList = dividedList.map(list => assignPosition(list))

  const back = () => {
    handleBack && handleBack()
  }

  return (
    <div style={{ width: '100%' }}>
      {positionList[selectedPage]?.map(item => (
        <Button
          key={item.id}
          value={item.labels?.[0]}
          position={item.position as HardwareButtonPosition}
          click={() => onSelect(item)}
        />
      ))}
      {listItemLength > HARDWARE_BUTTON_COUNT && (
        <>
          <Button
            value="<"
            position="l1"
            clearEvent={false}
            click={
              selectedPage !== 0
                ? () => setSelectedPage(prev => (prev !== 0 ? prev - 1 : prev))
                : back
            }
          />
          <Button
            value=">"
            position="r1"
            clearEvent={false}
            click={
              selectedPage !== pagesCount
                ? () => setSelectedPage(prev => (prev + 1 !== pagesCount ? prev + 1 : prev))
                : null
            }
          />
        </>
      )}
    </div>
  )
}
