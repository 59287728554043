import { sendSessionMessage as hwSendMessage } from '../api/hardwareService'
import { ServerMessage } from '../swagger'
import { CommandPayload } from '../types'

import { changePayload } from './utils'

interface InterceptorsProps {
  data: ServerMessage
  apiUrl: string
  setCommandPayload: (value: React.SetStateAction<CommandPayload[]>) => void
  setIsAcceptLoading: React.Dispatch<React.SetStateAction<boolean>>
}

export const hwInterceptors = async ({
  data,
  setCommandPayload,
  setIsAcceptLoading
}: InterceptorsProps) => {
  try {
    const { messageType } = data

    if (messageType === 'eAccept') {
      setIsAcceptLoading(true)
      const response = await hwSendMessage({ payload: data })
      if (response.status === 200) {
        setCommandPayload(
          changePayload({
            objectId: 'acceptId',
            responseData: response.data.responseData
          })
        )
      }
      setIsAcceptLoading(false)
    }
    if (messageType === 'eAcceptApprove') {
      await hwSendMessage({ payload: data })
    }
  } catch (error) {
    if (error instanceof Error) {
      // Type is narrowed to Error, safe to access Error properties
      console.log('🚀 ~ hwServer ~ error:', error.message)
    } else {
      // Generic error logging as we don't know the exact type
      console.error('🚀 ~ hwServer ~ error:', error)
    }
  }
}
