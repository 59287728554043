import { toast } from 'react-toastify'
import axios from 'axios'

import { GetDescriptorsFromImageRequest } from '../../../swagger'

axios.defaults.timeout = 1000 * 60 * 60 * 3

const BASE_FACE_RECOGNITIONS_URL = 'https://172.28.195.7:5000'

export const getImageDescriptors = async (requestBody: GetDescriptorsFromImageRequest) => {
  try {
    const response = await axios.post(
      `${BASE_FACE_RECOGNITIONS_URL}/api/v1/GetImageDescriptors`,
      requestBody
    )
    return response.data
  } catch (e) {
    if (axios.isCancel(e)) {
      console.log('Request canceled:', (e as { message: string }).message)
    } else if (axios.isAxiosError(e)) {
      toast.error(e.response?.data || e.message)
    }
    throw e
  }
}
