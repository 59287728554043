import { UserEntryScreenData } from '../swagger'

import { SendMessageProps } from '.'

export enum TemplateIds {
  ScrEurasianCashIn = 'scrEurasianCashIn',
  SrcReceiptWithQr = 'srcReceiptWithQr'
}

export interface BaseTemplateProps<T = UserEntryScreenData> {
  deviceClass: string
  messageData: T
  sendMessage: ({ objectId, responseData }: SendMessageProps) => Promise<void>
}
