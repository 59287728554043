import QRCode from 'react-qr-code'
import { Button, Checkbox, FormControlLabel, List, ListItemButton } from '@mui/material'
import classNames from 'classnames'

import { commonIconsMap } from '../../../../assets/icons/icons.map'
import { FaceRecognition } from '../../../../components/FaceRecognition'
import { HTMLContent } from '../../../../components/HTMLContent'
import { PageTitle } from '../../../../components/PageTitle'
import { Receipt } from '../../../../components/Receipt'
import { Timeout } from '../../../../components/TimeoutCountdown'
import { DEVICE_CLASSES, ELEMENT_TYPES, MESSAGE_TYPE } from '../../../../constants'
import { ReceiptWithQr } from '../../../../generalTemplates/ReceiptWithQr'
import { elementConfig } from '../../../../helpers/elementConfig'
import { changePayload } from '../../../../helpers/utils'
import {
  AcceptData,
  FaceRecognitionStartData,
  HtmlData,
  InputData,
  LabelData,
  ListData,
  QrData,
  ReceiptData,
  TableData,
  UserEntryCommandElement
} from '../../../../swagger'
import { Element } from '../../../../types'
import { ContentProps } from '../../../../types/content'
import { TemplateIds } from '../../../../types/templates'
import { Accept as AcceptTemplate } from '../../templates/Accept'
import { Accept } from '../Accept'
import { Input } from '../Input'
import { Numpad } from '../Numpad'
import { Table } from '../Table'

import styles from './styles.module.css'

/* Structure:
 - title
 - static
 - aux
 - confirm / cancel
 */

export const Content = ({
  templateId,
  messageData,
  messageType,
  commandPayload,
  validation,
  deviceClass,
  setCommandPayload,
  sendMessage
}: ContentProps) => {
  const { isValid, isAcceptLoading } = validation

  const {
    title,
    staticElements,
    cancelCommand,
    correctCommand,
    confirmCommand,
    auxCommandElements,
    inputElements
  } = messageData ?? {}

  const getElement = (element: Element) => {
    const { text, elemType } = elementConfig(element)

    const payloadResult = commandPayload.find(({ objectId }) => objectId === element.objectId)
      ?.responseData

    switch (elemType) {
      case ELEMENT_TYPES.eButton:
        return (
          <Button
            variant="outlined"
            className={styles.button}
            onClick={() => {
              sendMessage({ objectId: element.objectId })
            }}
          >
            <span>{text}</span>
          </Button>
        )
      case ELEMENT_TYPES.eInput:
        if ((element.elementData as InputData).inputHandler == 'eInputNative') {
          return (
            <Input
              placeholder={text}
              value={payloadResult?.value}
              className={styles.input}
              objectId={element.objectId}
              isOptional={(element.elementData as InputData).isOptional}
              setCommandPayload={setCommandPayload}
            />
          )
        } else {
          return (
            <Numpad
              input={{
                value: payloadResult?.value,
                objectId: element.objectId,
                setCommandPayload,
                mask: (element.elementData as InputData).formatMask,
                label: (element.elementData as InputData).label?.text
              }}
            />
          )
        }
      case ELEMENT_TYPES.eCheckbox:
        return (
          <FormControlLabel
            control={
              <Checkbox
                className={styles.checkbox}
                color="primary"
                checked={!!payloadResult?.checked}
                onChange={e => {
                  setCommandPayload(
                    changePayload({
                      objectId: element.objectId,
                      responseData: { checked: e.target.checked }
                    })
                  )
                }}
              />
            }
            label={text}
            labelPlacement="start"
            className={styles.checkboxLabel}
          />
        )
      case ELEMENT_TYPES.eList:
        return (
          <div style={{ width: '100%' }}>
            <List className={styles.list} component="nav" aria-label={text}>
              {(element?.elementData as ListData)?.items?.map(item => (
                <ListItemButton
                  key={item.id}
                  classes={{ root: styles.listItem, selected: styles.listItemSelected }}
                  selected={payloadResult?.values?.[0] === item.id}
                  onClick={() => {
                    setCommandPayload(
                      changePayload({
                        objectId: element.objectId,
                        responseData: { values: [item.id] }
                      })
                    )
                  }}
                >
                  {item.labels?.[0]}
                </ListItemButton>
              ))}
            </List>
          </div>
        )
      case ELEMENT_TYPES.eQR: {
        const { text: qrText } = element?.elementData as QrData

        return (
          <div className="qr">
            <QRCode size={128} value={qrText ?? ''} />
          </div>
        )
      }
      case ELEMENT_TYPES.eTable:
        return <Table elementData={element.elementData as TableData} />

      case ELEMENT_TYPES.eLabel: {
        const { text: labelText, subText } = element.elementData as LabelData

        return (
          <div className={styles.label} key={labelText}>
            {labelText}
            <span>{subText}</span>
          </div>
        )
      }

      case ELEMENT_TYPES.eHtml:
        return <HTMLContent html={(element.elementData as HtmlData).content} />
      default:
        return `Unsupported elem, type: ${elemType}`
    }
  }

  const allElements = [...(auxCommandElements ?? []), ...(inputElements ?? [])] as Element[]

  const elementsContent = allElements.reduce(
    (acc, elem) => {
      return { ...acc, content: [...acc.content, getElement(elem)] }
    },
    { buttons: [], content: [] as Array<string | React.JSX.Element> }
  )

  const renderActionButton = (
    command: UserEntryCommandElement,
    className: string,
    disabled?: boolean
  ) => {
    const { objectId, elementData } = command
    const { text, icon } = elementData?.label ?? {}

    const IconSVG = icon?.data ? commonIconsMap[icon.data] : null

    return (
      <Button
        disabled={!!disabled}
        variant="contained"
        className={classNames(styles.actionButtons, className)}
        onClick={() => sendMessage({ objectId })}
      >
        {IconSVG ? <IconSVG style={{ width: 18, height: 'auto', marginRight: 10 }} /> : null}
        {text}
      </Button>
    )
  }

  const contentByTemplate = (template?: string) => {
    switch (template) {
      case TemplateIds.ScrEurasianCashIn:
        return (
          <AcceptTemplate
            deviceClass={deviceClass}
            messageData={messageData as AcceptData}
            sendMessage={sendMessage}
          />
        )
      case TemplateIds.SrcReceiptWithQr:
        return (
          <ReceiptWithQr
            deviceClass={deviceClass}
            messageData={messageData ?? {}}
            sendMessage={sendMessage}
          />
        )

      default:
        return null
    }
  }

  return (
    contentByTemplate(templateId) ?? (
      <div className={styles.content}>
        <div className={styles.block}>
          {title && <PageTitle title={title} />}

          <div className={styles.controlContent}>
            {staticElements && (
              <div className={styles.static}>
                {staticElements.map(element => getElement(element as Element))}
              </div>
            )}
            {messageType === MESSAGE_TYPE.eFaceRecognition && (
              <FaceRecognition
                messageData={messageData as FaceRecognitionStartData}
                sendMessage={sendMessage}
              />
            )}
            {messageType === MESSAGE_TYPE.eAccept && (
              <Accept messageData={messageData as AcceptData} />
            )}
            {messageType === MESSAGE_TYPE.eReceipt && (
              <div className={styles.receivedText}>
                <Receipt messageData={messageData as ReceiptData} />
              </div>
            )}
            {!!elementsContent.content.length && (
              <div
                className={classNames(styles.actionContent, {
                  [styles.multiInputsForm]: allElements.some(
                    element => element?.commandElementType !== ELEMENT_TYPES.eButton
                  )
                })}
              >
                {elementsContent.content}
              </div>
            )}
            <Timeout />
          </div>
        </div>
        {deviceClass === DEVICE_CLASSES.eDeviceClassKiosk && (
          <div className={styles.buttonsContainer}>
            {cancelCommand?.elementData && renderActionButton(cancelCommand, styles.cancel)}
            {correctCommand?.elementData && (
              <div>{renderActionButton(correctCommand, styles.correct)}</div>
            )}
            {confirmCommand?.elementData &&
              renderActionButton(confirmCommand, styles.confirm, isValid || isAcceptLoading)}
          </div>
        )}
      </div>
    )
  )
}
