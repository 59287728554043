import { AxiosRequestConfig } from 'axios'

import { DeviceMessage } from '../swagger'

import { initClient } from './client'

const HARDWARE_SERVICE_URL =
  window?.GLOBAL_ENV?.REACT_APP_HARD_WARE_SERVICE || 'http://10.100.1.72:1228'

interface RequestProps<T = {}> {
  payload?: T
  apiUrl?: string
  axiosConfig?: AxiosRequestConfig
}

const _client = initClient(HARDWARE_SERVICE_URL)

export const sendSessionMessage = async (props: RequestProps<DeviceMessage>) => {
  const { payload = {}, axiosConfig } = props

  return _client.post('/session-message', payload, axiosConfig)
}
